/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { ChannelModel, ChannelModelType } from "./ChannelModel"
import { channelModelPrimitives, ChannelModelSelector } from "./ChannelModel.base"
import { ChatMessageModel, ChatMessageModelType } from "./ChatMessageModel"
import { chatMessageModelPrimitives, ChatMessageModelSelector } from "./ChatMessageModel.base"
import { GameArchiveEntityModel, GameArchiveEntityModelType } from "./GameArchiveEntityModel"
import { gameArchiveEntityModelPrimitives, GameArchiveEntityModelSelector } from "./GameArchiveEntityModel.base"
import { GameEntityModel, GameEntityModelType } from "./GameEntityModel"
import { gameEntityModelPrimitives, GameEntityModelSelector } from "./GameEntityModel.base"
import { GameResponseModel, GameResponseModelType } from "./GameResponseModel"
import { gameResponseModelPrimitives, GameResponseModelSelector } from "./GameResponseModel.base"
import { GameSnapshotModel, GameSnapshotModelType } from "./GameSnapshotModel"
import { gameSnapshotModelPrimitives, GameSnapshotModelSelector } from "./GameSnapshotModel.base"
import { InputPlayerStatsModel, InputPlayerStatsModelType } from "./InputPlayerStatsModel"
import { inputPlayerStatsModelPrimitives, InputPlayerStatsModelSelector } from "./InputPlayerStatsModel.base"
import { JwtModel, JwtModelType } from "./JwtModel"
import { jwtModelPrimitives, JwtModelSelector } from "./JwtModel.base"
import { MessageContractModel, MessageContractModelType } from "./MessageContractModel"
import { messageContractModelPrimitives, MessageContractModelSelector } from "./MessageContractModel.base"
import { PlayerModel, PlayerModelType } from "./PlayerModel"
import { playerModelPrimitives, PlayerModelSelector } from "./PlayerModel.base"
import { PlayerStatBestHandWonModel, PlayerStatBestHandWonModelType } from "./PlayerStatBestHandWonModel"
import { playerStatBestHandWonModelPrimitives, PlayerStatBestHandWonModelSelector } from "./PlayerStatBestHandWonModel.base"
import { PlayerStatBiggestWinModel, PlayerStatBiggestWinModelType } from "./PlayerStatBiggestWinModel"
import { playerStatBiggestWinModelPrimitives, PlayerStatBiggestWinModelSelector } from "./PlayerStatBiggestWinModel.base"
import { PlayerStatsEntityModel, PlayerStatsEntityModelType } from "./PlayerStatsEntityModel"
import { playerStatsEntityModelPrimitives, PlayerStatsEntityModelSelector } from "./PlayerStatsEntityModel.base"
import { ShareModel, ShareModelType } from "./ShareModel"
import { shareModelPrimitives, ShareModelSelector } from "./ShareModel.base"
import { TournamentModel, TournamentModelType } from "./TournamentModel"
import { tournamentModelPrimitives, TournamentModelSelector } from "./TournamentModel.base"
import { TournamentStatsModel, TournamentStatsModelType } from "./TournamentStatsModel"
import { tournamentStatsModelPrimitives, TournamentStatsModelSelector } from "./TournamentStatsModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { VerificationCodeModel, VerificationCodeModelType } from "./VerificationCodeModel"
import { verificationCodeModelPrimitives, VerificationCodeModelSelector } from "./VerificationCodeModel.base"


import { ChatKind } from "./ChatKindEnum"
import { GameKind } from "./GameKindEnum"
import { GameModes } from "./GameModesEnum"
import { LoginFromProvider } from "./LoginFromProviderEnum"
import { StatsKind } from "./StatsKindEnum"
import { TournamentKind } from "./TournamentKindEnum"
import { UserRoles } from "./UserRolesEnum"

export type Bet = {
  amount: number
  kind: string
}
export type BetCommand = {
  bet: Bet
  channelName: string
  kind?: string
  playerId: string
  userId: string
}
export type BlindCommand = {
  blindLevel: number
  channelName: string
  kind?: string
  playerId: string
  userId: string
}
export type BuyCommand = {
  amount: number
  channelName: string
  kind?: string
  playerId: string
  userId: string
}
export type GameCommand = {
  channelName: string
  kind?: string
  userId: string
}
export type GameStartCommand = {
  channelName: string
  enableAutoStart: boolean
  kind?: string
  options?: GameStartOptions
  userId: string
}
export type GameStartOptions = {
  blindMultipler?: number
  blindTimerCountdown: number
  mode: string
}
export type InputChannelSubscription = {
  channelName: string
  connectionId?: string
  operationId?: string
  operationType?: string
  userId: string
}
export type InputUser = {
  avatar?: string
  email?: string
  isGuest?: boolean
  loginFrom?: string
  name?: string
  socialId?: string
}
export type JoinGameCommand = {
  channelName: string
  kind?: string
  playerName: string
  playKind?: string
  startingChips?: number
  userId: string
}
export type PlayerStatBestHandWon1 = {
  createdAt: any
  rankedHandIndex?: number
  rankedHandName: string
}
export type PlayerStatBiggestWin1 = {
  chipsWon: number
  createdAt: any
}
export type TableCommand = {
  channelName: string
  kind?: string
  playerId: string
  userId: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {

}


/**
* Enums for the names of base graphql actions
*/
export enum RootStoreBaseQueries {
queryCheckContext="queryCheckContext",
queryGetAgoraToken="queryGetAgoraToken",
queryGetAllChannels="queryGetAllChannels",
queryGetAllGames="queryGetAllGames",
queryGetChatsByChannelName="queryGetChatsByChannelName",
queryGetDeletedGame="queryGetDeletedGame",
queryGetPlayerById="queryGetPlayerById",
queryGetPlayerByName="queryGetPlayerByName",
queryGetPlayerGames="queryGetPlayerGames",
queryGetPlayerGlobalStats="queryGetPlayerGlobalStats",
queryGetPlayerStats="queryGetPlayerStats",
queryGetShareByChannelName="queryGetShareByChannelName",
queryGetShareByHash="queryGetShareByHash",
queryGetTournamentPlayerRank="queryGetTournamentPlayerRank",
queryGetTournaments="queryGetTournaments",
queryGetTournamentStats="queryGetTournamentStats",
queryHello="queryHello",
queryHelloAuth="queryHelloAuth",
queryMe="queryMe",
queryPause="queryPause",
queryRemoveStaleGames="queryRemoveStaleGames",
queryUnpause="queryUnpause"
}
export enum RootStoreBaseMutations {
mutateActivePlayers="mutateActivePlayers",
mutateAddPlayer="mutateAddPlayer",
mutateAllInPlayers="mutateAllInPlayers",
mutateAllPlayersAreAllIn="mutateAllPlayersAreAllIn",
mutateBroadcast="mutateBroadcast",
mutateCalculateNextBlinds="mutateCalculateNextBlinds",
mutateCaptureBets="mutateCaptureBets",
mutateChatMessage="mutateChatMessage",
mutateCollectCards="mutateCollectCards",
mutateConnect="mutateConnect",
mutateConnectUser="mutateConnectUser",
mutateCountFromButton="mutateCountFromButton",
mutateCreateChannel="mutateCreateChannel",
mutateCreateGame="mutateCreateGame",
mutateCreateShare="mutateCreateShare",
mutateCreateTournament="mutateCreateTournament",
mutateDealCards="mutateDealCards",
mutateDeleteChannel="mutateDeleteChannel",
mutateDeleteGame="mutateDeleteGame",
mutateDeletePlayerStats="mutateDeletePlayerStats",
mutateDeleteTournamentById="mutateDeleteTournamentById",
mutateDeleteUser="mutateDeleteUser",
mutateDisconnect="mutateDisconnect",
mutateDoAllInForPlayer="mutateDoAllInForPlayer",
mutateDoBetForPlayer="mutateDoBetForPlayer",
mutateDoBuyForPlayer="mutateDoBuyForPlayer",
mutateDoCallForPlayer="mutateDoCallForPlayer",
mutateDoCheckForPlayer="mutateDoCheckForPlayer",
mutateDoFoldForPlayer="mutateDoFoldForPlayer",
mutateDoLeaveGameForPlayer="mutateDoLeaveGameForPlayer",
mutateDoNoActionForPlayer="mutateDoNoActionForPlayer",
mutateDoPostBlindForPlayer="mutateDoPostBlindForPlayer",
mutateDoShowdown="mutateDoShowdown",
mutateEndGame="mutateEndGame",
mutateFoldedPlayers="mutateFoldedPlayers",
mutateGetBetHints="mutateGetBetHints",
mutateHideCardsForTeacher="mutateHideCardsForTeacher",
mutateInternalState="mutateInternalState",
mutateIsHeadsUpPlay="mutateIsHeadsUpPlay",
mutateJoinChannel="mutateJoinChannel",
mutateJoinGame="mutateJoinGame",
mutateLeaveChannel="mutateLeaveChannel",
mutateNewHand="mutateNewHand",
mutateNextBoardAction="mutateNextBoardAction",
mutateNextPlayer="mutateNextPlayer",
mutateNextTurn="mutateNextTurn",
mutateOneActivePlayerLeft="mutateOneActivePlayerLeft",
mutateOutPlayers="mutateOutPlayers",
mutatePauseGame="mutatePauseGame",
mutatePayBlinds="mutatePayBlinds",
mutatePlayersHaveFinishedBetting="mutatePlayersHaveFinishedBetting",
mutateRefreshToken="mutateRefreshToken",
mutateRemovedPlayerByTeacher="mutateRemovedPlayerByTeacher",
mutateRemoveOutPlayers="mutateRemoveOutPlayers",
mutateResendCode="mutateResendCode",
mutateResetInactivePlayers="mutateResetInactivePlayers",
mutateResumeGame="mutateResumeGame",
mutateSeatedPlayerByTeacher="mutateSeatedPlayerByTeacher",
mutateSendMessage="mutateSendMessage",
mutateSetPlayerBuyIn="mutateSetPlayerBuyIn",
mutateSetTheButton="mutateSetTheButton",
mutateSetupNewHand="mutateSetupNewHand",
mutateShowCardsForTeacher="mutateShowCardsForTeacher",
mutateSocialLogin="mutateSocialLogin",
mutateStartGame="mutateStartGame",
mutateTerminateGame="mutateTerminateGame",
mutateUnseatPlayerByTeacher="mutateUnseatPlayerByTeacher",
mutateUpdateGame="mutateUpdateGame",
mutateUpdateHandStrengths="mutateUpdateHandStrengths",
mutateUpdatePlayersFromWaitlist="mutateUpdatePlayersFromWaitlist",
mutateUpdateUser="mutateUpdateUser"
}

/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = withTypedRefs<Refs>()(MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['Channel', () => ChannelModel], ['ChatMessage', () => ChatMessageModel], ['GameArchiveEntity', () => GameArchiveEntityModel], ['GameEntity', () => GameEntityModel], ['GameResponse', () => GameResponseModel], ['GameSnapshot', () => GameSnapshotModel], ['InputPlayerStats', () => InputPlayerStatsModel], ['JWT', () => JwtModel], ['MessageContract', () => MessageContractModel], ['Player', () => PlayerModel], ['PlayerStatBestHandWon', () => PlayerStatBestHandWonModel], ['PlayerStatBiggestWin', () => PlayerStatBiggestWinModel], ['PlayerStatsEntity', () => PlayerStatsEntityModel], ['Share', () => ShareModel], ['Tournament', () => TournamentModel], ['TournamentStats', () => TournamentStatsModel], ['User', () => UserModel], ['VerificationCode', () => VerificationCodeModel]], [], "js"))
  .props({

  })
  .actions(self => ({
    queryCheckContext(variables?: {  }, options: QueryOptions = {}) {
      return self.query<{ checkContext: boolean }>(`query checkContext { checkContext }`, variables, options)
    },
    queryGetAgoraToken(variables: { channelName: string, role: number, uid: number }, options: QueryOptions = {}) {
      return self.query<{ getAgoraToken: string }>(`query getAgoraToken($channelName: String!, $role: Float!, $uid: Float!) { getAgoraToken(channelName: $channelName, role: $role, uid: $uid) }`, variables, options)
    },
    queryGetAllChannels(variables: { page: number }, resultSelector: string | ((qb: ChannelModelSelector) => ChannelModelSelector) = channelModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getAllChannels: ChannelModelType[]}>(`query getAllChannels($page: Float!) { getAllChannels(page: $page) {
        ${typeof resultSelector === "function" ? resultSelector(new ChannelModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetAllGames(variables: { page: number }, options: QueryOptions = {}) {
      return self.query<{ getAllGames: string }>(`query getAllGames($page: Float!) { getAllGames(page: $page) }`, variables, options)
    },
    queryGetChatsByChannelName(variables: { channelName: string }, resultSelector: string | ((qb: ChatMessageModelSelector) => ChatMessageModelSelector) = chatMessageModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getChatsByChannelName: ChatMessageModelType[]}>(`query getChatsByChannelName($channelName: String!) { getChatsByChannelName(channelName: $channelName) {
        ${typeof resultSelector === "function" ? resultSelector(new ChatMessageModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetDeletedGame(variables: { gameId: string }, resultSelector: string | ((qb: GameArchiveEntityModelSelector) => GameArchiveEntityModelSelector) = gameArchiveEntityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getDeletedGame: GameArchiveEntityModelType}>(`query getDeletedGame($gameId: String!) { getDeletedGame(gameId: $gameId) {
        ${typeof resultSelector === "function" ? resultSelector(new GameArchiveEntityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetPlayerById(variables?: {  }, resultSelector: string | ((qb: PlayerModelSelector) => PlayerModelSelector) = playerModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getPlayerById: PlayerModelType}>(`query getPlayerById { getPlayerById {
        ${typeof resultSelector === "function" ? resultSelector(new PlayerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetPlayerByName(variables: { name: string }, resultSelector: string | ((qb: PlayerModelSelector) => PlayerModelSelector) = playerModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getPlayerByName: PlayerModelType}>(`query getPlayerByName($name: String!) { getPlayerByName(name: $name) {
        ${typeof resultSelector === "function" ? resultSelector(new PlayerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetPlayerGames(variables: { userId: string }, options: QueryOptions = {}) {
      return self.query<{ getPlayerGames: any }>(`query getPlayerGames($userId: String!) { getPlayerGames(userId: $userId) }`, variables, options)
    },
    queryGetPlayerGlobalStats(variables: { userId: string }, resultSelector: string | ((qb: PlayerStatsEntityModelSelector) => PlayerStatsEntityModelSelector) = playerStatsEntityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getPlayerGlobalStats: PlayerStatsEntityModelType}>(`query getPlayerGlobalStats($userId: String!) { getPlayerGlobalStats(userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new PlayerStatsEntityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetPlayerStats(variables: { gameId: string, userId: string }, resultSelector: string | ((qb: PlayerStatsEntityModelSelector) => PlayerStatsEntityModelSelector) = playerStatsEntityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getPlayerStats: PlayerStatsEntityModelType}>(`query getPlayerStats($gameId: String!, $userId: String!) { getPlayerStats(gameId: $gameId, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new PlayerStatsEntityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetShareByChannelName(variables: { channelName: string }, resultSelector: string | ((qb: ShareModelSelector) => ShareModelSelector) = shareModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getShareByChannelName: ShareModelType}>(`query getShareByChannelName($channelName: String!) { getShareByChannelName(channelName: $channelName) {
        ${typeof resultSelector === "function" ? resultSelector(new ShareModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetShareByHash(variables: { hash: string }, resultSelector: string | ((qb: ShareModelSelector) => ShareModelSelector) = shareModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getShareByHash: ShareModelType}>(`query getShareByHash($hash: String!) { getShareByHash(hash: $hash) {
        ${typeof resultSelector === "function" ? resultSelector(new ShareModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetTournamentPlayerRank(variables: { tournamentId: string, userId: string }, options: QueryOptions = {}) {
      return self.query<{ getTournamentPlayerRank: number }>(`query getTournamentPlayerRank($tournamentId: String!, $userId: String!) { getTournamentPlayerRank(tournamentId: $tournamentId, userId: $userId) }`, variables, options)
    },
    queryGetTournaments(variables?: {  }, resultSelector: string | ((qb: TournamentModelSelector) => TournamentModelSelector) = tournamentModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getTournaments: TournamentModelType[]}>(`query getTournaments { getTournaments {
        ${typeof resultSelector === "function" ? resultSelector(new TournamentModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryGetTournamentStats(variables: { tournamentId: string }, resultSelector: string | ((qb: TournamentStatsModelSelector) => TournamentStatsModelSelector) = tournamentStatsModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ getTournamentStats: TournamentStatsModelType}>(`query getTournamentStats($tournamentId: String!) { getTournamentStats(tournamentId: $tournamentId) {
        ${typeof resultSelector === "function" ? resultSelector(new TournamentStatsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryHello(variables?: {  }, options: QueryOptions = {}) {
      return self.query<{ hello: string }>(`query hello { hello }`, variables, options)
    },
    queryHelloAuth(variables?: {  }, options: QueryOptions = {}) {
      return self.query<{ helloAuth: string }>(`query helloAuth { helloAuth }`, variables, options)
    },
    queryMe(variables?: {  }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ me: UserModelType}>(`query me { me {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryPause(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ pause: GameEntityModelType}>(`query pause($command: TableCommand!) { pause(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryRemoveStaleGames(variables?: {  }, options: QueryOptions = {}) {
      return self.query<{ removeStaleGames: boolean }>(`query removeStaleGames { removeStaleGames }`, variables, options)
    },
    queryUnpause(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ unpause: GameEntityModelType}>(`query unpause($command: TableCommand!) { unpause(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    mutateActivePlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ activePlayers: GameEntityModelType}>(`mutation activePlayers($command: TableCommand!) { activePlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateAddPlayer(variables: { cards: string[], chips: number, gameId: string, id: string, name: string, state: string, userId: string }, resultSelector: string | ((qb: PlayerModelSelector) => PlayerModelSelector) = playerModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ addPlayer: PlayerModelType}>(`mutation addPlayer($cards: [String!]!, $chips: Float!, $gameId: String!, $id: String!, $name: String!, $state: String!, $userId: String!) { addPlayer(cards: $cards, chips: $chips, gameId: $gameId, id: $id, name: $name, state: $state, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new PlayerModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateAllInPlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ allInPlayers: GameEntityModelType}>(`mutation allInPlayers($command: TableCommand!) { allInPlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateAllPlayersAreAllIn(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ allPlayersAreAllIn: GameEntityModelType}>(`mutation allPlayersAreAllIn($command: TableCommand!) { allPlayersAreAllIn(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateBroadcast(variables: { channelName: string, message: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ broadcast: any }>(`mutation broadcast($channelName: String!, $message: String!) { broadcast(channelName: $channelName, message: $message) }`, variables, optimisticUpdate)
    },
    mutateCalculateNextBlinds(variables: { command: BlindCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ calculateNextBlinds: GameEntityModelType}>(`mutation calculateNextBlinds($command: BlindCommand!) { calculateNextBlinds(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCaptureBets(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ captureBets: GameEntityModelType}>(`mutation captureBets($command: TableCommand!) { captureBets(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateChatMessage(variables: { channelName: string, chatKind: string, color: string, message: string, userName: string }, resultSelector: string | ((qb: ChatMessageModelSelector) => ChatMessageModelSelector) = chatMessageModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ chatMessage: ChatMessageModelType}>(`mutation chatMessage($channelName: String!, $chatKind: String!, $color: String!, $message: String!, $userName: String!) { chatMessage(channelName: $channelName, chatKind: $chatKind, color: $color, message: $message, userName: $userName) {
        ${typeof resultSelector === "function" ? resultSelector(new ChatMessageModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCollectCards(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ collectCards: GameEntityModelType}>(`mutation collectCards($command: TableCommand!) { collectCards(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateConnect(variables: { connectionId: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ connect: any }>(`mutation connect($connectionId: String!) { connect(connectionId: $connectionId) }`, variables, optimisticUpdate)
    },
    mutateConnectUser(variables: { connectionId: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ connectUser: any }>(`mutation connectUser($connectionId: String!) { connectUser(connectionId: $connectionId) }`, variables, optimisticUpdate)
    },
    mutateCountFromButton(variables: { command: TableCommand, times: number }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ countFromButton: GameResponseModelType}>(`mutation countFromButton($command: TableCommand!, $times: Float!) { countFromButton(command: $command, times: $times) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateChannel(variables: { channelName: string, userId: string }, resultSelector: string | ((qb: ChannelModelSelector) => ChannelModelSelector) = channelModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createChannel: ChannelModelType}>(`mutation createChannel($channelName: String!, $userId: String!) { createChannel(channelName: $channelName, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new ChannelModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateGame(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createGame: GameEntityModelType}>(`mutation createGame($command: GameCommand!) { createGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateShare(variables: { channelName: string, firstName: string, gameMode: string, hash: string, message: string, url: string, userId: string }, resultSelector: string | ((qb: ShareModelSelector) => ShareModelSelector) = shareModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createShare: ShareModelType}>(`mutation createShare($channelName: String!, $firstName: String!, $gameMode: String!, $hash: String!, $message: String!, $url: String!, $userId: String!) { createShare(channelName: $channelName, firstName: $firstName, gameMode: $gameMode, hash: $hash, message: $message, url: $url, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new ShareModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateCreateTournament(variables: { actionTime?: number, blindTimer?: number, endTime: any, gameIds: string[], name: string, startingChips?: number, startTime: any, type: string }, resultSelector: string | ((qb: TournamentModelSelector) => TournamentModelSelector) = tournamentModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ createTournament: TournamentModelType}>(`mutation createTournament($actionTime: Float, $blindTimer: Float, $endTime: DateTime!, $gameIds: [String!]!, $name: String!, $startingChips: Float, $startTime: DateTime!, $type: String!) { createTournament(actionTime: $actionTime, blindTimer: $blindTimer, endTime: $endTime, gameIds: $gameIds, name: $name, startingChips: $startingChips, startTime: $startTime, type: $type) {
        ${typeof resultSelector === "function" ? resultSelector(new TournamentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDealCards(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ dealCards: GameEntityModelType}>(`mutation dealCards($command: TableCommand!) { dealCards(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeleteChannel(variables: { channelName: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ deleteChannel: boolean }>(`mutation deleteChannel($channelName: String!) { deleteChannel(channelName: $channelName) }`, variables, optimisticUpdate)
    },
    mutateDeleteGame(variables: { id: string }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteGame: GameEntityModelType}>(`mutation deleteGame($id: String!) { deleteGame(_id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDeletePlayerStats(variables: { id: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ deletePlayerStats: boolean }>(`mutation deletePlayerStats($id: String!) { deletePlayerStats(_id: $id) }`, variables, optimisticUpdate)
    },
    mutateDeleteTournamentById(variables: { id: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ deleteTournamentById: boolean }>(`mutation deleteTournamentById($id: String!) { deleteTournamentById(_id: $id) }`, variables, optimisticUpdate)
    },
    mutateDeleteUser(variables: { id: string }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ deleteUser: UserModelType}>(`mutation deleteUser($id: String!) { deleteUser(_id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDisconnect(variables: { connectionId: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ disconnect: any }>(`mutation disconnect($connectionId: String!) { disconnect(connectionId: $connectionId) }`, variables, optimisticUpdate)
    },
    mutateDoAllInForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doAllInForPlayer: GameResponseModelType}>(`mutation doAllInForPlayer($command: TableCommand!) { doAllInForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoBetForPlayer(variables: { command: BetCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doBetForPlayer: GameResponseModelType}>(`mutation doBetForPlayer($command: BetCommand!) { doBetForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoBuyForPlayer(variables: { command: BuyCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doBuyForPlayer: GameResponseModelType}>(`mutation doBuyForPlayer($command: BuyCommand!) { doBuyForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoCallForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doCallForPlayer: GameResponseModelType}>(`mutation doCallForPlayer($command: TableCommand!) { doCallForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoCheckForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doCheckForPlayer: GameResponseModelType}>(`mutation doCheckForPlayer($command: TableCommand!) { doCheckForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoFoldForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doFoldForPlayer: GameResponseModelType}>(`mutation doFoldForPlayer($command: TableCommand!) { doFoldForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoLeaveGameForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doLeaveGameForPlayer: GameEntityModelType}>(`mutation doLeaveGameForPlayer($command: TableCommand!) { doLeaveGameForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoNoActionForPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doNoActionForPlayer: GameEntityModelType}>(`mutation doNoActionForPlayer($command: TableCommand!) { doNoActionForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoPostBlindForPlayer(variables: { command: BetCommand }, resultSelector: string | ((qb: GameResponseModelSelector) => GameResponseModelSelector) = gameResponseModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doPostBlindForPlayer: GameResponseModelType}>(`mutation doPostBlindForPlayer($command: BetCommand!) { doPostBlindForPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameResponseModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateDoShowdown(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ doShowdown: GameEntityModelType}>(`mutation doShowdown($command: TableCommand!) { doShowdown(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateEndGame(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ endGame: GameEntityModelType}>(`mutation endGame($command: GameCommand!) { endGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateFoldedPlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ foldedPlayers: GameEntityModelType}>(`mutation foldedPlayers($command: TableCommand!) { foldedPlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateGetBetHints(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ getBetHints: GameEntityModelType}>(`mutation getBetHints($command: TableCommand!) { getBetHints(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateHideCardsForTeacher(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ hideCardsForTeacher: GameEntityModelType}>(`mutation hideCardsForTeacher($command: GameCommand!) { hideCardsForTeacher(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateInternalState(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ internalState: GameEntityModelType}>(`mutation internalState($command: GameCommand!) { internalState(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateIsHeadsUpPlay(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ isHeadsUpPlay: GameEntityModelType}>(`mutation isHeadsUpPlay($command: TableCommand!) { isHeadsUpPlay(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateJoinChannel(variables: { channelName: string, userId: string }, resultSelector: string | ((qb: ChannelModelSelector) => ChannelModelSelector) = channelModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ joinChannel: ChannelModelType}>(`mutation joinChannel($channelName: String!, $userId: String!) { joinChannel(channelName: $channelName, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new ChannelModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateJoinGame(variables: { command: JoinGameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ joinGame: GameEntityModelType}>(`mutation joinGame($command: JoinGameCommand!) { joinGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateLeaveChannel(variables: { channelName: string, userId: string }, resultSelector: string | ((qb: ChannelModelSelector) => ChannelModelSelector) = channelModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ leaveChannel: ChannelModelType}>(`mutation leaveChannel($channelName: String!, $userId: String!) { leaveChannel(channelName: $channelName, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new ChannelModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateNewHand(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ newHand: GameEntityModelType}>(`mutation newHand($command: GameCommand!) { newHand(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateNextBoardAction(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ nextBoardAction: GameEntityModelType}>(`mutation nextBoardAction($command: TableCommand!) { nextBoardAction(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateNextPlayer(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ nextPlayer: GameEntityModelType}>(`mutation nextPlayer($command: TableCommand!) { nextPlayer(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateNextTurn(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ nextTurn: GameEntityModelType}>(`mutation nextTurn($command: TableCommand!) { nextTurn(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateOneActivePlayerLeft(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ oneActivePlayerLeft: GameEntityModelType}>(`mutation oneActivePlayerLeft($command: TableCommand!) { oneActivePlayerLeft(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateOutPlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ outPlayers: GameEntityModelType}>(`mutation outPlayers($command: TableCommand!) { outPlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutatePauseGame(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ pauseGame: GameEntityModelType}>(`mutation pauseGame($command: GameCommand!) { pauseGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutatePayBlinds(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ payBlinds: GameEntityModelType}>(`mutation payBlinds($command: TableCommand!) { payBlinds(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutatePlayersHaveFinishedBetting(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ playersHaveFinishedBetting: GameEntityModelType}>(`mutation playersHaveFinishedBetting($command: TableCommand!) { playersHaveFinishedBetting(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRefreshToken(variables: { userId: string }, resultSelector: string | ((qb: JwtModelSelector) => JwtModelSelector) = jwtModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ refreshToken: JwtModelType}>(`mutation refreshToken($userId: String!) { refreshToken(userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(new JwtModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRemovedPlayerByTeacher(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ removedPlayerByTeacher: GameEntityModelType}>(`mutation removedPlayerByTeacher($command: TableCommand!) { removedPlayerByTeacher(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateRemoveOutPlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ removeOutPlayers: GameEntityModelType}>(`mutation removeOutPlayers($command: TableCommand!) { removeOutPlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateResendCode(variables: { phoneNumber: string }, resultSelector: string | ((qb: VerificationCodeModelSelector) => VerificationCodeModelSelector) = verificationCodeModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ resendCode: VerificationCodeModelType}>(`mutation resendCode($phoneNumber: String!) { resendCode(phoneNumber: $phoneNumber) {
        ${typeof resultSelector === "function" ? resultSelector(new VerificationCodeModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateResetInactivePlayers(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ resetInactivePlayers: GameEntityModelType}>(`mutation resetInactivePlayers($command: TableCommand!) { resetInactivePlayers(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateResumeGame(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ resumeGame: GameEntityModelType}>(`mutation resumeGame($command: GameCommand!) { resumeGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSeatedPlayerByTeacher(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ seatedPlayerByTeacher: GameEntityModelType}>(`mutation seatedPlayerByTeacher($command: TableCommand!) { seatedPlayerByTeacher(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSendMessage(variables: { message: string }, optimisticUpdate?: () => void) {
      return self.mutate<{ sendMessage: any }>(`mutation sendMessage($message: String!) { sendMessage(message: $message) }`, variables, optimisticUpdate)
    },
    mutateSetPlayerBuyIn(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ setPlayerBuyIn: GameEntityModelType}>(`mutation setPlayerBuyIn($command: TableCommand!) { setPlayerBuyIn(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSetTheButton(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ setTheButton: GameEntityModelType}>(`mutation setTheButton($command: TableCommand!) { setTheButton(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSetupNewHand(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ setupNewHand: GameEntityModelType}>(`mutation setupNewHand($command: GameCommand!) { setupNewHand(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateShowCardsForTeacher(variables: { command: GameCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ showCardsForTeacher: GameEntityModelType}>(`mutation showCardsForTeacher($command: GameCommand!) { showCardsForTeacher(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateSocialLogin(variables: { avatar: string, email: string, isGuest?: boolean, loginFrom: string, name: string, phoneNumber: string, socialId: string, userRoles: string[] }, resultSelector: string | ((qb: JwtModelSelector) => JwtModelSelector) = jwtModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ socialLogin: JwtModelType}>(`mutation socialLogin($avatar: String!, $email: String!, $isGuest: Boolean, $loginFrom: String!, $name: String!, $phoneNumber: String!, $socialId: String!, $userRoles: [String!]!) { socialLogin(avatar: $avatar, email: $email, isGuest: $isGuest, loginFrom: $loginFrom, name: $name, phoneNumber: $phoneNumber, socialId: $socialId, userRoles: $userRoles) {
        ${typeof resultSelector === "function" ? resultSelector(new JwtModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateStartGame(variables: { command: GameStartCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ startGame: GameEntityModelType}>(`mutation startGame($command: GameStartCommand!) { startGame(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateTerminateGame(variables: { id: string }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ terminateGame: GameEntityModelType}>(`mutation terminateGame($id: String!) { terminateGame(_id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUnseatPlayerByTeacher(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ unseatPlayerByTeacher: GameEntityModelType}>(`mutation unseatPlayerByTeacher($command: TableCommand!) { unseatPlayerByTeacher(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateGame(variables: { autostart: boolean, channelName: string }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateGame: GameEntityModelType}>(`mutation updateGame($autostart: Boolean!, $channelName: String!) { updateGame(autostart: $autostart, channelName: $channelName) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateHandStrengths(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateHandStrengths: GameEntityModelType}>(`mutation updateHandStrengths($command: TableCommand!) { updateHandStrengths(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdatePlayersFromWaitlist(variables: { command: TableCommand }, resultSelector: string | ((qb: GameEntityModelSelector) => GameEntityModelSelector) = gameEntityModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updatePlayersFromWaitlist: GameEntityModelType}>(`mutation updatePlayersFromWaitlist($command: TableCommand!) { updatePlayersFromWaitlist(command: $command) {
        ${typeof resultSelector === "function" ? resultSelector(new GameEntityModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    mutateUpdateUser(variables: { id: string, user: InputUser }, resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(), optimisticUpdate?: () => void) {
      return self.mutate<{ updateUser: UserModelType}>(`mutation updateUser($id: String!, $user: InputUser!) { updateUser(_id: $id, user: $user) {
        ${typeof resultSelector === "function" ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    subscribeMessages(variables: { channelSubscription: InputChannelSubscription }, resultSelector: string | ((qb: MessageContractModelSelector) => MessageContractModelSelector) = messageContractModelPrimitives.toString(), onData?: (item: any) => void, onError?: (error: Error) => void) {
      return self.subscribe<{ messages: MessageContractModelType}>(`subscription messages($channelSubscription: InputChannelSubscription!) { messages(channelSubscription: $channelSubscription) {
        ${typeof resultSelector === "function" ? resultSelector(new MessageContractModelSelector()).toString() : resultSelector}
      } }`, variables, onData, onError)
    },
  })))
