import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import {
  Image,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import Modal, { ModalProps } from 'react-native-modal'
import { useStore } from '../../models'
import LottieView from 'lottie-react-native'
export interface CustomModalProps extends ModalProps {
  children: any
  title?: string
  visible: boolean
  dismissDisabled?: boolean
  onClose: () => void
  gradientColor?: string[]
  showCloseButton?: boolean
  containerStyle?: StyleProp<ViewStyle>
  errorMsg?: string
  shadowColor?: string
  showShadow?: boolean
  isGameOver?: boolean
}

export const CommonModal = (props: CustomModalProps) => {
  const {
    dismissDisabled,
    shadowColor,
    showShadow,
    children,
    title,
    visible,
    onClose,
    gradientColor,
    showCloseButton,
    errorMsg,
    containerStyle,
    isGameOver,
    ...rest
  } = props
  const store = useStore()

  const [isLottieAnimOnFront, setIsLottieAnimOnFront] = React.useState(true)
  const [contentViewDimension, setContentViewDimension] = React.useState([20, 20])

  const doNotCloseLeaderBoard = () => {
    if (Platform.OS !== 'web') {
      let result = store.session.modalQueue.length > 0 ? store.session.modalQueue[0].kind : ''
      return result === 'leaderBoard'
    } else {
      return false
    }
  }

  React.useEffect(() => {
    if (!!isGameOver && isLottieAnimOnFront) {
      setTimeout(() => {
        setIsLottieAnimOnFront(false)
      }, 8200)
    }
    return () => {
      setIsLottieAnimOnFront(true)
    }
  }, [])

  return (
    <Modal {...rest} isVisible={visible} coverScreen={true} shouldRasterizeIOS style={{ margin: 0 }}>
      {!!isGameOver ? (
        <>
          <LottieView
            source={require('../../../app/components/modal/stars-winner.json')}
            autoPlay={true}
            loop={true}
            resizeMode={'cover'}
            speed={0.35}
            renderMode={'HARDWARE'}
            style={
              !isLottieAnimOnFront
                ? {
                    bottom: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'orange'
                  }
                : {
                    bottom: 20,
                    position: 'absolute',
                    zIndex: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'orange'
                  }
            }
          />
          <View
            style={[
              styles.modalContainer,
              {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                height: contentViewDimension[1] + 20,
                width: contentViewDimension[0],
                position: 'absolute',
                zIndex: 3,
                // marginBottom: 0,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              },
              containerStyle,
            ]}
          >
            {showCloseButton !== false ? (
              <TouchableOpacity
                onPress={onClose}
                style={[styles.modalCloseContainer, { backgroundColor: 'transparent', left: -15 }]}
              >
                <View style={{ height: 40, width: 40 }} />
              </TouchableOpacity>
            ) : null}
          </View>
        </>
      ) : null}
      <View style={styles.errorMessageContainer}>
        {errorMsg ? (
          <Text allowFontScaling={false} style={styles.errorText}>
            {errorMsg}
          </Text>
        ) : null}
      </View>
      <Pressable
        onPress={
          (dismissDisabled !== undefined && dismissDisabled === true) || doNotCloseLeaderBoard() === true
            ? () => {}
            : onClose
        }
        style={styles.modal}
      >
        {title ? (
          <Text allowFontScaling={false} style={styles.modalTitle}>
            {title}
          </Text>
        ) : null}
        <Pressable
          onPress={(e) => {
            e.preventDefault()
          }}
          style={[
            showShadow
              ? {
                  elevation: 5,
                  shadowColor: shadowColor ? shadowColor : '#ffffff',
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.5,
                  shadowRadius: 5,
                }
              : {},
          ]}
        >
          <LinearGradient
            colors={gradientColor ? gradientColor : ['#49CDF2', '#49CDF2', '#9444FB']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={[styles.modalContainer, containerStyle]}
            onLayout={(e) => {
              const height = e.nativeEvent.layout.height
              const width = e.nativeEvent.layout.width
              setContentViewDimension([width, height])
            }}
          >
            {children}
          </LinearGradient>
          {showCloseButton !== false ? (
            <TouchableOpacity onPress={onClose} style={[styles.modalCloseContainer]}>
              <Image source={require('../../../assets/icons/close.png')} style={styles.closeImage} />
            </TouchableOpacity>
          ) : null}
        </Pressable>
      </Pressable>
    </Modal>
  )
}

const styles = StyleSheet.create({
  closeImage: {
    height: 30,
    width: 30,
  },
  modalCloseContainer: {
    position: 'absolute',
    left: 20,
    top: -8,
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 14,
    padding: 2,
  },
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
    justifyContent: 'center',
  },
  modalTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 22,
    fontFamily: 'HeroNew-BoldItalic',
    fontWeight: '700',
    paddingVertical: 10,
  },
  errorMessageContainer: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    top: 50,
    height: 20,
  },
  errorText: {
    color: '#E34234',
    textAlign: 'center',
  },
  lottieContainer: {
    left: 0,
    height: '100%',
    width: '100%',
  },
})
