import 'react-native-get-random-values'

import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { FlatList, Image, SafeAreaView, TouchableOpacity, View } from 'react-native'

import { GameStates, PlayerStates } from '@poker-powher/poker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { ActionModal, Text } from '../../components'
import { translate } from '../../i18n/translate'
import { useStore } from '../../models'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import { fontFamily } from '../../theme/fontFamily'
import GameCard from './gameCard'
import styles from './styles'

const createTournament = require('../../../assets/menu/join.png')
let timer: NodeJS.Timer

export const ActiveGameScreen = observer(() => {
  const navigation = useNavigation()
  const store = useStore()
  const [activeGames, setActiveGames] = React.useState([])
  const [channelName, setChannelName] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
  const [currentGameId, setCurrentGameId] = React.useState('')
  const [playerRole, setPlayerRole] = React.useState('')

  const fetchActiveGameList = async () => {
    try {
      const response = await store.session.getActiveTournament()
      if (response) {
        setActiveGames(response)
      }
    } catch (error) {
      console.log('Error in fetching the tournament', error)
    }
  }

  const onRagisterGameModal = (channelName: string, isValidTime: boolean, isLocked: boolean) => {
    const isTeacher = store.session.isTeacher
    if (isTeacher && isValidTime && isLocked === false) {
      setVisible(true)
    } else {
      getGameHashCode(channelName)
    }
  }

  const onSelectRole = (role: string) => {
    store.session.setPlayKind(role)
    setPlayerRole(role)
    setVisible(false)
    getGameHashCode(channelName)
  }

  const getGameHashCode = async (channelName: string) => {
    try {
      console.log('Channel name for hash code: ', channelName)
      const hash = await store.session.getGameHashCode(channelName)
      if (hash !== undefined && hash.length > 0) {
        await store.session.fetchShare(hash)
        const channelName = store.session.share?.channelName
        if (channelName) {
          await store.session.joinChannel(channelName)
          if (store.session.user) {
            store.telemetry.setUser({ ...store.session.user, gameId: store.session.gameId })
          }
          if (store.session.isLobbyScreenEnabled) {
            navigation.navigate(DrawerRoutes.gameLobby)
          } else {
            navigation.navigate(DrawerRoutes.game)
          }
        }
      }
    } catch (error) {
      console.log('Unable to fetch hash code', error)
    }
  }

  useFocusEffect(() => {
    store.session.teardown()
    // We poll teardown in Tournament lobby, so need toggle back on the tournament mode
    store.session.setIsTournamentModeOn(true)
    store.session.setPlayKind(playerRole)
    timer = setInterval(() => {
      fetchActiveGameList()
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  const openDeleteModal = (gameId: string) => {
    setDeleteModalVisible(true)
    setCurrentGameId(gameId)
  }

  const onDeleteGame = async () => {
    setDeleteModalVisible(false)
    await store.session.terminateGame(currentGameId)
  }

  const Header = useMemo(() => {
    return (
      <View style={styles.screenHeaderContainer}>
        <Text
          tx="activeGameScreen.activeGame"
          style={{ fontSize: 24, fontFamily: fontFamily.sfProDisplayHeavy }}
        />
        {store.session.isTeacher && (
          <TouchableOpacity onPress={() => navigation.navigate(DrawerRoutes.createTournament)}>
            <Image source={createTournament} style={{ width: 25, height: 25 }} />
          </TouchableOpacity>
        )}
      </View>
    )
  }, [activeGames.length])

  const EmptyGameList = () => {
    return (
      <View style={styles.emptyContainer}>
        <Text allowFontScaling={false}
          style={styles.emptyText}>{translate('activeGameScreen.noCurrentGame')}</Text>
      </View>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={activeGames}
        extraData={activeGames}
        numColumns={2}
        ListHeaderComponent={Header}
        ListEmptyComponent={<EmptyGameList />}
        renderItem={({ item }) => {
          if (item.gameIds[0] === undefined) {
            return null
          }
          const players =
            item.gameIds[0].forPlayers && item.gameIds[0].forPlayers[0] ? item.gameIds[0].forPlayers[0].players : []

          const observerPlayers = players.filter((player) => {
            return player.state.tag === PlayerStates.Observer.tag
          })
          const gameStartTime = item.gameIds[0].snapshot.table[0].gameStartTime
          const gameState = item.gameIds[0].snapshot.table[0].state.tag
          // Note: This is an offset to match the 15 minutes on game engine side
          const validDuration = gameStartTime + 16 * 60 * 1000 - 30000
          const currentTimestamp = new Date().getTime()
          const isValidDuration = currentTimestamp < validDuration
          const isValidTime = gameStartTime !== 0 ? isValidDuration && gameState !== GameStates.ReadyToPlay.tag : true
          return (
            <View style={{ flex: 0.5 }}>
              <GameCard
                isTeacher={store.session.isTeacher}
                title={item.name}
                seatLeft={9 - players.length + observerPlayers.length}
                channelName={item.gameIds[0].channelName}
                color={item.color}
                isLocked={item.isLocked}
                openDeleteModal={(id: string) => openDeleteModal(id)}
                isValidDuration={
                  gameStartTime !== 0 ? isValidDuration && gameState !== GameStates.ReadyToPlay.tag : true
                }
                gameId={item.gameIds[0]._id}
                onRegister={async () => {
                  setChannelName(item.gameIds[0].channelName)
                  store.session.setIsTournamentModeOn(true)
                  store.session.setIsGameOver(false)
                  store.session.setTournamentId(item._id)
                  store.session.setStartingChips(item.startingChips)
                  store.session.setActionTime(item.actionTime * 1000)
                  store.session.setBlindTime(item.blindTimer * 60)
                  store.session.setRemainingTime(item.blindTimer * 60)
                  await onRagisterGameModal(item.gameIds[0].channelName, isValidTime, item.isLocked)
                }}
              />
            </View>
          )
        }}
        keyExtractor={(item) => item._id}
        style={{ marginHorizontal: 5, flex: 1 }}
      />
      <ActionModal
        key={'userRole'}
        onSelect={(role: string) => onSelectRole(role)}
        message={translate('join.confirmMessageBeforeJoinTheTable') ?? ''}
        visible={visible}
        buttonTitle1={translate('common.observe')}
        buttonTitle2={translate('common.play')}
        onModalHide={() => setVisible(false)}
        onClose={() => setVisible(false)}
        type={'userRole'}
        shouldHideSecondButton={true}
      />
      <ActionModal
        key={'DeleteTournamentGameModal'}
        onAction={onDeleteGame}
        onModalHide={() => setDeleteModalVisible(false)}
        onClose={() => setDeleteModalVisible(false)}
        message={translate('activeGameScreen.deleteConfirmation')}
        visible={deleteModalVisible}
      />
    </SafeAreaView>
  )
})
