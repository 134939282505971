import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { Profanity, ProfanityOptions } from '@2toad/profanity'

import { ChatKind } from '../api-store/ChatKindEnum'
import { withRootStore } from '../extensions'

export const ChatMessageModel = types.model('ChatMessageModel').props({
  channelName: types.string || undefined,
  userName: types.string || undefined,
  message: types.string || undefined,
  chatKind: ChatKind.user || ChatKind.dealer || ChatKind.engine || ChatKind.observer || ChatKind.admin,
  userId: types.string,
  color: types.string || undefined,
})

export const options = new ProfanityOptions()

/**
 * Chat Store Model
 */
export const SessionChatStoreModel = types
  .model('SessionChatStoreModel')
  .props({
    channelChats: types.optional(types.array(ChatMessageModel), []),
    isChatting: types.optional(types.boolean, false),
    backgroundedChatQueue: types.optional(types.array(ChatMessageModel), []),
    iGameBackgrounded: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
  .volatile((self) => {
    options.wholeWord = true
    options.grawlix = '*****'
    // With time - add words to be whitelisted
    const whitelistWords = ['hoar', 'nob']
    const profanity = new Profanity(options)
    profanity.whitelist.addWords(whitelistWords)
    return {
      profanity,
    }
  })
  .actions((self) => ({
    setIsChatting(value: boolean) {
      self.isChatting = value
    },
    // Adds a chat only locally
    checkDuplicateMessage(chatToCheck: any) {
      // We trust player chats, we need to make sure the engine doesn't spit out many dealer chats.
      if (chatToCheck.chatKind === ChatKind.dealer && self.channelChats.length > 0) {
        const dealerChats = self.channelChats.filter((chat) => chat.chatKind === ChatKind.dealer)
        if (dealerChats[dealerChats.length - 1] !== chatToCheck) {
          return false
        } else {
          return true
        }
      }
    },
  }))
  .actions((self) => ({
    setIsChatting(value: boolean) {
      self.isChatting = value
    },
    // Adds a chat only locally
    addChatLocally(channelName: string, userName: string, message: string, chatKind: ChatKind, color: string) {
      const constructedChat = {
        channelName: channelName,
        userName: userName, // If it's a user-generated chat, this name will be above the chat bubble - else - hidden.
        message: message,
        chatKind: chatKind,
        userId: userName,
        color: color,
      }
      if (!self.checkDuplicateMessage(constructedChat)) {
        self.channelChats.push(constructedChat)
      }
    },
    clearBackgroundChatQueue() {
      self.backgroundedChatQueue.clear()
    },
    backgroundChatEnqueue(channelName: string, userName: string, message: string, chatKind: ChatKind, color: string) {
      const constructedChat = {
        channelName: channelName,
        userName: userName, // If it's a user-generated chat, this name will be above the chat bubble - else - hidden.
        message: message,
        chatKind: chatKind,
        userId: userName,
        color: color,
      }
      self.backgroundedChatQueue.push(constructedChat)
    },
    setIsGameBackgrounded(value: boolean) {
      self.iGameBackgrounded = value
    },
  }))
  .actions((self) => ({
    // This hits API chatMessage endpoint.
    async sendChat(message: string, chatKind: ChatKind, color: string) {
      const channelName = self.rootStore.session.channel?.channelName
      const userName = self.rootStore.session.user?.name
      message = self.profanity.censor(message).toString()
      // @TODO We need better checks here.
      if (message !== '' && channelName && userName !== '' && userName) {
        try {
          console.warn('try : doSendChat', channelName, message, color)

          // vv incase we want to do some client-side chat loading.
          // const response = await self.rootStore.api.mutateChatMessage({
          await self.rootStore.api.mutateChatMessage({
            channelName,
            userName,
            message,
            chatKind,
            color,
          })
        } catch (error) {
          console.error('session-chat sendChat Error', error)
          throw error
        }
      } else {
        console.error('session-chat sendChat Error: undefined/empty strings', message, channelName)
      }
    },
    // This gets called from session-messages.
    async handleChat(incomingChat: any) {
      const { message, kind, userId, userName, uniqueId, color } = incomingChat.payload
      const { channelName, messageId } = incomingChat
      const newChat = {
        channelName,
        userName,
        uniqueId,
        messageId,
        message,
        chatKind: kind,
        color,
        userId,
      }

      if (incomingChat && message && kind && userId && userName) {
        if (!self.channelChats.includes(newChat)) {
          self.channelChats.push(newChat)
          console.log('handleChat session-chat successful receipt')
        } else {
          console.warn('session-chat handleChat: Prevented duplicate message.')
        }
      }
    },
    // Gets old chats and adds them to chatOverlay and chatPreview
    async getChannelChats() {
      const channelName = self.rootStore.session.channel?.channelName
      if (channelName && channelName != '') {
        try {
          const channelMessages = await self.rootStore.api.queryGetChatsByChannelName({ channelName })
          const { getChatsByChannelName } = channelMessages
          getChatsByChannelName.map((key) => {
            if (key.message && key.channelName && key.chatKind && key.userName && key.color) {
              self.addChatLocally(
                key.channelName,
                key.userName,
                key.message,
                ChatKind[key.chatKind as keyof typeof ChatKind], // API returns stringified chatKind, so we use it against enum.
                key.color
              )
            }
          })
        } catch (error) {
          console.error('session-chat getChannelchats Error', error)
          throw error
        }
      }
    },
    // Clears local copy of channelChats.
    clearChannelChats() {
      self.channelChats.splice(0, self.channelChats.length)
      console.log('clearChannelChats session-chat clearing list during cleanup.')
    },
  }))

/**
 * The SessionChatStore instance.
 */
export type SessionChatStore = Instance<typeof SessionChatStoreModel>

/**
 * The data of a SessionChatStore.
 */
export type SessionChatStoreSnapshot = SnapshotOut<typeof SessionChatStoreModel>
