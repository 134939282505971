import { debounce, get, orderBy } from 'lodash'
import { getEnv, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { Alert, AppState, AppStateStatus } from 'react-native'

import { Bet, BetKind, GameModes, GameStates, Player, PlayerStates, PotWinner } from '@poker-powher/poker'
import { TableSnapshot } from '@poker-powher/poker/lib/model/Snapshot'

import { GridPreset } from '../../components/Grid/Grid'
import { translate } from '../../i18n'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import { RootNavigation } from '../../navigation/navigation-utilities'
import { MappingError } from '../../utils/errors/errors.mapping'
import { emitter, PlayerActionTimerExpired } from '../../utils/events'
import {
  ChatKind,
  GameEntityModelType,
  GameKind,
  GameSnapshotModel,
  GameSnapshotModelType,
  PlayerStatsEntityModel,
  PlayerStatsEntityModelType,
  TournamentKind,
  UserRoles,
} from '../api-store'
import { BetCommand, BlindCommand, BuyCommand, GameCommand, TableCommand } from '../api-store/RootStore.base'
import { withRootStore } from '../extensions'
import { RootStoreEnv } from '../RootStoreEnv'
import { events } from './analytics/analytics-events'
import { GameEventQueue } from './game/game-events'
import { GameModalQueue, GameWinner } from './game/game-modals'

let interval: NodeJS.Timer

export enum PlayKind {
  observe = 'observe',
  play = 'play',
}

export const PlayerModel = types.model('PlayerModel').props({
  id: types.identifier,
  actions: types.optional(types.array(types.string), ['blank', 'blank', 'blank']),
  player: types.maybe(types.frozen<Player>()),
  index: types.number,
  mappedIndex: types.number,
  bet: types.number,
  bets: types.maybe(types.frozen()),
  cards: types.optional(types.array(types.string), ['blank', 'blank']),
  chips: types.number,
  name: types.string,
  userId: types.string,
  role: types.string,
  color: types.string,
  buyIn: types.maybe(types.boolean),
  // table
  dealer: types.number,
  bigBlindPlayer: types.number,
  smallBlindPlayer: types.number,
  currentPlayer: types.number,
  myPlayer: types.number,
  playerState: types.maybe(types.frozen()),
})

export const NextBlindModel = types.model('NextBlindModel').props({
  big: types.number,
  small: types.number,
})

export type PlayerType = Instance<typeof PlayerModel>
export type NextBlindType = Instance<typeof NextBlindModel>

const defaultActionTime: number = 60000

/**
 * A SessionGamesStore model.
 */

export const SessionGameModel = types
  .model('SessionGameModel')
  .props({
    allIn: types.optional(types.string, ''),
    events: types.optional(GameEventQueue, {}),
    game: types.maybe(GameSnapshotModel),
    gridPreset: types.optional(types.string, GridPreset.noTable),
    isShowdown: types.optional(types.boolean, false),
    modals: types.optional(GameModalQueue, {}),
    myPlayer: types.maybe(PlayerModel),
    players: types.optional(types.array(PlayerModel), []),
    winners: types.optional(types.array(types.frozen<PotWinner>()), []),
    showWinningHandLottie: types.optional(types.boolean, false),
    // @TODO: This is the player's option modal that enables video and teacher controls
    isOptionModalEnabled: types.optional(types.boolean, true),
    isFourColoredDeckEnabled: types.optional(types.boolean, false),
    isLobbyScreenEnabled: types.optional(types.boolean, false),
    showCardsForAllin: types.optional(types.boolean, false),
    winnerCommunityCards: types.optional(types.array(types.string), []),
    isCardsVisible: types.optional(types.boolean, false),
    playerUserIdForTeacher: types.optional(types.string, ''),
    lastAction: types.maybe(types.frozen<Bet>()),
    isGameAutostartEnabled: types.optional(types.boolean, false),
    myPlayerStats: types.maybe(PlayerStatsEntityModel),
    hasUserPreFold: types.optional(types.boolean, false),
    pauseGameTimestamp: types.optional(types.number, 0),
    gameResetTimestamp: types.optional(types.number, 0),
    mIOutOfGame: types.optional(types.boolean, false),
    isAnyPlayerGoesOutOfChips: types.optional(types.boolean, false),
    isGameOver: types.optional(types.boolean, false),
    isGameDeleted: types.optional(types.boolean, false),
    gameId: types.optional(types.string, ''),
    currentBlind: types.optional(NextBlindModel, { big: 0, small: 0 }),
    nextBlind: types.optional(NextBlindModel, { big: 0, small: 0 }),
    currentBlindTimestamp: types.optional(types.number, new Date().getTime()),
    isTournamentModeOn: types.optional(types.boolean, false),
    shouldHighlightCurrentPlayer: types.optional(types.boolean, false),
    isCalledForNextBlind: types.optional(types.boolean, false),
    actionTime: types.optional(types.number, 60000),
    startingChips: types.optional(types.number, 10000),
    blindTime: types.optional(types.number, 300),
    hasUserDeclinedToBuy: types.optional(types.boolean, false),
    remainingTime: types.optional(types.number, 300),
    gameStartTime: types.optional(types.number, 0),
    blindLevel: types.optional(types.number, 0),
    tournamentId: types.optional(types.string, ''),
    leaderboardData: types.maybe(types.frozen()),
    isLeaderboardAccessedFromDrawer: types.optional(types.boolean, false),
    myRank: types.optional(types.number, 0),
    playerActionDuration: types.optional(types.number, 60000),
    isWinnerInfoDisplayedToOutPlayer: types.optional(types.boolean, false),
    isLeaderboardDisplayedToOutPlayer: types.optional(types.boolean, false),
    hasUserTappedOnAction: types.optional(types.boolean, false),
    isFirstTime: types.optional(types.boolean, true),
    pauseRemainingTime: types.optional(types.number, 0),
    isPauseGame: types.optional(types.boolean, false),
    isFirstTimeLostMoney: types.optional(types.boolean, true),
    isWinningModalAutoClose: types.optional(types.boolean, true),
    pauseCounter: types.optional(types.number, 0),
    iGameBackgrounded: types.optional(types.boolean, false),
    playerRanks: types.optional(types.array(types.string), []),
    playKind: types.optional(types.string, ''),
    lastActiveCheckpoint: types.optional(types.number, new Date().getTime()),
    observerPlayerNumber: types.optional(types.array(types.number), []),
    isLeaderBoardModalAutoClose: types.optional(types.boolean, true),
    playerJoinedTimeStamp: types.optional(types.number, 0),
    // isLeaderboardDisplay: types.optional(types.string, ''),
  })
  .extend(withRootStore)
  .volatile((self) => {
    const avatarMap: Record<string, JSX.Element> = {}
    return {
      appState: AppState.currentState,
      avatarMap,
    }
  })
  .views((self) => ({
    get defaultTable(): TableSnapshot {
      return self.game?.table?.['0'] ?? null
    },
    get gamePlayers() {
      console.log('self.game?.players', self.game?.players)
      return self.game?.players ?? []
    },
    get seatedPlayingPlayers() {
      const seatedPlayingPlayers = self.players.filter((player) => {
        const observerId = player.player && player.player.observerId ? player.player.observerId : -1
        return (
          [
            PlayerStates.AllIn.tag,
            PlayerStates.Out.tag,
            PlayerStates.Waiting.tag,
            PlayerStates.InPlay.tag,
            PlayerStates.Folded.tag,
            PlayerStates.Disconnected.tag,
            PlayerStates.ForeverOut.tag,
          ].includes(player.playerState.tag) || observerId === 1
        )
      })
      console.log('seatedPlayingPlayers', seatedPlayingPlayers)
      return seatedPlayingPlayers
    },
    get seatedPlayers() {
      const seatedPlayers = self.players.filter((player) =>
        [
          PlayerStates.AllIn.tag,
          PlayerStates.Out.tag,
          PlayerStates.Waiting.tag,
          PlayerStates.InPlay.tag,
          PlayerStates.Folded.tag,
          PlayerStates.Disconnected.tag,
          PlayerStates.ForeverOut.tag,
        ].includes(player.playerState.tag)
      )
      return seatedPlayers
    },
    get observerPlayer() {
      const observers: string[] = []
      const observerPlayers = self.players.filter((player) =>
        [PlayerStates.Observer.tag].includes(player.playerState.tag)
      )
      if (observerPlayers) {
        observerPlayers.forEach((observer) => {
          observers.push(observer.userId)
        })
      }
      return observers
    },
    get observerPlayerName() {
      //NOTE: self.players will only contain the seated players not all.
      const gamePlayers = self.rootStore.session.gamePlayers
      const observers: string[] = []
      const observerPlayers = gamePlayers.filter((player) => [PlayerStates.Observer.tag].includes(player.state.tag))
      if (observerPlayers) {
        observerPlayers.forEach((observer) => {
          observers.push(observer.name)
        })
      }
      return observers
    },
    get teacherWithObserver() {
      const totalPlayers = self.rootStore.session.gamePlayers
      if (self.rootStore.session.seatedPlayers.length === 9) {
        const teacherWithObserver: Player[] = totalPlayers.filter((player: any) => {
          return player.userRoles.includes(UserRoles.teacher) && player.state.tag === PlayerStates.Observer.tag
        })
        const formattedTeacherWithObserver: Player[] = teacherWithObserver.map((element, index) => {
          return self.rootStore.session.createMappedPlayer(element, index + 10)
        })
        return formattedTeacherWithObserver
      } else {
        return []
      }
    },
    get isMyPlayerSeated() {
      return [
        PlayerStates.AllIn.tag,
        PlayerStates.Out.tag,
        PlayerStates.Waiting.tag,
        PlayerStates.InPlay.tag,
        PlayerStates.Folded.tag,
        PlayerStates.Disconnected.tag,
      ].includes(self.myPlayer?.playerState.tag ?? '')
    },
    get isMyTurn() {
      const currentPlayer = get(self.game, 'table[0].currentPlayer', -1)
      const myPlayerIndex = self.myPlayer?.index ?? -2
      return myPlayerIndex === currentPlayer
    },
    get myActions() {
      let actions = []

      let currentPlayerAction = self.game && self.game.currentPlayerAction ? self.game.currentPlayerAction : []

      actions =
        self.game && self.game.forPlayer && self.game.forPlayer.state !== PlayerStates.Waiting.tag
          ? self.game.forPlayer.actions
          : []

      // @DEBUG to catch missing actions.
      // if (actions <= 0) {
      //   console.warn("## currentPlayerAction", currentPlayerAction)
      // } else {
      //   console.warn("## regular actions", actions, "AND currentPlayerAction:", currentPlayerAction)
      // }

      actions = actions.length === 0 ? currentPlayerAction : actions
      return actions
    },
    get myHoleCards() {
      const userId = self.rootStore.session.user?._id
      const players = self.rootStore.session.seatedPlayers
      if (players.length > 0) {
        const myData: PlayerType[] = players.filter((player) => player.userId === userId)
        if (myData.length > 0) {
          return myData[0].cards
        } else {
          return []
        }
      } else {
        return []
      }
    },
    get isMyPlayerFoldedOrWaiting() {
      const myData = self.myPlayer
      if (
        myData?.playerState.tag === PlayerStates.Out.tag ||
        myData?.playerState.tag === PlayerStates.Waiting.tag ||
        myData?.playerState.tag === PlayerStates.Folded.tag
      ) {
        return true
      } else {
        return false
      }
    },
    get chipsCountOnGameReset() {
      return self.myPlayer?.chips
    },
    get blindTimerCheckpoint() {
      const currentTimestamp = new Date().getTime()
      const checkpoint = get(self.game, 'table[0].blindTimerCheckpoint', currentTimestamp)
      return checkpoint
    },
  }))
  .views((self) => ({
    get isGamePaused() {
      return (self.game?.gameState?.tag ?? '') === GameStates.Paused.tag
    },
    get isGameReset() {
      return (self.game?.gameState?.tag ?? '') === GameStates.Reset.tag
    },
    get isPlayingGame() {
      // return (self.game?.gameState?.tag ?? '') === GameStates.Playing.tag
      const currentGameState = self.game?.gameState?.tag ?? ''
      // console.log('isPlayingGame', currentGameState, self.seatedPlayers.length)
      return [GameStates.Playing.tag, GameStates.Reset.tag, GameStates.Paused.tag, GameStates.Finished.tag].includes(
        currentGameState
      )
    },
    get shouldPrefoldButtonVisible() {
      const tableBiggestBet = self.rootStore.session.getBiggestBet()
      const myData = self.myPlayer
      if (myData) {
        if (myData.bet !== tableBiggestBet && tableBiggestBet > 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    getPlayerIndex(playerId: number) {
      return self.gamePlayers.findIndex((player: any) => player.id === playerId)
    },
    getBigBlindPlayerIndex() {
      return self.defaultTable?.bigBlindPlayer ?? 0
    },
    getSmallBlindPlayerIndex() {
      return self.defaultTable?.smallBlindPlayer ?? 0
    },
    getDealerIndex() {
      return self.defaultTable?.buttonPlayer ?? 0
    },
    getcurrentPlayerIndex() {
      return self.defaultTable?.currentPlayer ?? 0
    },
    getBiggestBet() {
      return self.defaultTable?.biggestBet ?? 0
    },
    getSecondsPassed() {
      if (self.game) {
        const currentCheckpoint = new Date().getTime()
        if (self.rootStore.session.isGameReset) {
          const timeLeft = Math.ceil((30000 - (currentCheckpoint - self.gameResetTimestamp)) / 1000)
          return Math.min(30, Math.max(timeLeft + 5, 0))
        }
        const activePlayers = self.seatedPlayers.filter(
          (player: any) => player.playerState.tag !== PlayerStates.Waiting.tag
        )
        const activeSortedPlayers = orderBy(activePlayers, 'player.checkpoint')
        const lastActiveCheckpoint =
          activeSortedPlayers && activeSortedPlayers.length > 0
            ? activeSortedPlayers[activeSortedPlayers.length - 1].player?.checkpoint
            : new Date().getTime()
        const pauseTimestamp = self.pauseGameTimestamp
        if (currentCheckpoint && lastActiveCheckpoint) {
          if (pauseTimestamp > lastActiveCheckpoint) {
            const diff = Math.abs(pauseTimestamp - lastActiveCheckpoint)
            return diff
          }
          const diff = Math.abs(currentCheckpoint - lastActiveCheckpoint)
          return diff + 10
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    get myPlayingStatus(): string {
      const myData = self.myPlayer
      if (myData) {
        return myData.playerState.tag
      } else {
        return ''
      }
    },
    get isCardsVisibleByTeacher(): boolean {
      const tableData = self.defaultTable
      if (tableData) {
        return tableData.isCardsVisibleByTeacher
      } else {
        return false
      }
    },
    get isCardsVisibleOnShowDown(): boolean {
      const tableData = self.defaultTable
      if (tableData) {
        return tableData.isCardsVisibleOnShowDown
      } else {
        return false
      }
    },
  }))
  .actions((self) => ({
    getObserverList() {
      let observers: any = []
      const totalPlayers = self.gamePlayers
      const inGamePlayers = self.seatedPlayers
      // IMP: Logic works when 'Observer' tag is available
      if (totalPlayers) {
        totalPlayers.forEach((element: any) => {
          const index = inGamePlayers.findIndex((player) => player.userId === element.userId)
          if (index < 0) {
            observers.push(element)
          }
        })
      }
      return observers
    },
    async getNextBlinds() {
      try {
        // const currentPlayer = get(self.game, 'table[0].currentPlayerId', -1)
        // const currentPlayerData =
        // currentPlayer > -1 ? self.seatedPlayers.filter((player: any) => player.id !== currentPlayer) : []
        // const userId = currentPlayerData.length > 0 ? currentPlayerData[0].userId : ''
        const userId = self.rootStore.session.user?._id.toString() ?? null
        const channelName = self.rootStore.session.channel?.channelName
        const me = self.players.find((player) => player.userId === userId)
        const currentPlayerId = self.defaultTable?.currentPlayerId
        console.log('session.game getNextBlinds currentPlayerId', currentPlayerId)
        console.log('session.game getNextBlinds self.defaultTable', JSON.stringify(self.defaultTable))
        console.log('session.game getNextBlinds self.game', JSON.stringify(self.game))

        if (me && userId && channelName && self.blindLevel <= 15) {
          console.log('session.game getNextBlinds for channel', channelName)
          if (channelName && userId) {
            const command: BlindCommand = {
              channelName,
              userId,
              playerId: currentPlayerId,
              blindLevel: self.blindLevel,
            }
            console.log('BlindCommand===>>', command)
            const response = await self.rootStore.api.mutateCalculateNextBlinds({ command })
            console.log('session.game getNextBlinds', response)
            self.rootStore.session.setIsCalledForNextBlind(true)
          }
        }
      } catch (error) {
        console.error('session.game getNextBlinds error', error)
      }
    },
    createMappedPlayer(player: any, index: number) {
      const userId = self.rootStore.session.user?._id.toString()
      const bigBlindPlayer = self.getBigBlindPlayerIndex()
      const smallBlindPlayer = self.getSmallBlindPlayerIndex()
      const dealer = self.getDealerIndex()
      const currentPlayer = self.getcurrentPlayerIndex()
      const gamePlayers = self.gamePlayers
      const myPlayerIndex = gamePlayers.findIndex((player: Player) => player.userId === userId)
      console.log('session.game createMappedPlayer', index, player)
      // const playerIndex = player?.observerId && player?.observerId === 1 ? 10 : index
      return {
        ...player,
        id: player.id.toString(),
        key: `player-${index}-${player.name}`,
        bigBlindPlayer,
        smallBlindPlayer,
        dealer,
        currentPlayer,
        index,
        player,
        playerState: player.state,
        myPlayer: myPlayerIndex,
        mappedIndex: index,
      }
    },
    getPlayerActionDuration() {
      const duration = self.actionTime - self.getSecondsPassed() + 5
      // if (duration <= 0) {
      //   self.rootStore.session.noActionPerformed()
      // }
      return duration
    },
    getTimePassedPercentage() {
      const passedPercentage = (self.actionTime - self.getSecondsPassed() + 5) / self.actionTime
      return passedPercentage
    },
    getPlayerRank(player_id: string) {
      console.log('###--> PlayerRanks.length', self.playerRanks.length)
      for (var i = 0; i < self.playerRanks.length; i++) {
        if (self.playerRanks[i] === player_id) {
          console.log('###--> Fround a match')
          return self.seatedPlayers.length - i
        }
      }
      return 0
    },
  }))
  .actions((self) => ({
    setisFirstTimeLostMoney(value: boolean) {
      self.isFirstTimeLostMoney = value
    },
    setisWinningModalAutoClose(value: boolean) {
      self.isWinningModalAutoClose = value
    },
    setIsLeaderBoardModalAutoClose(value: boolean) {
      self.isLeaderBoardModalAutoClose = value
    },
    setiGameBackgrounded(value: boolean) {
      self.iGameBackgrounded = value
    },
    setisShowdown(value: boolean) {
      self.isShowdown = value
    },
    resetAvatarMap() {
      self.avatarMap = {}
    },
    setShowWinningHandLottie(value: boolean) {
      self.showWinningHandLottie = value
    },
    setIsOptionModalEnabled(value: boolean) {
      self.isOptionModalEnabled = value
    },
    setIsFourColoredDeckEnabled(value: boolean) {
      self.isFourColoredDeckEnabled = value
    },
    setIsLobbyScreenEnabled(value: boolean) {
      self.isLobbyScreenEnabled = value
    },
    setIsCardsVisible(value: boolean) {
      self.isCardsVisible = value
    },
    setMyPlayerStats(stats: PlayerStatsEntityModelType) {
      self.myPlayerStats = stats
    },
    setPlayerUserIdForTeacher(userId: string) {
      self.playerUserIdForTeacher = userId
    },
    resetLastPlayerAction() {
      self.lastAction = undefined
    },
    setIsGameAutostartEnabled(value: boolean) {
      self.isGameAutostartEnabled = value
    },
    setHasUserPreFold(value: boolean) {
      self.hasUserPreFold = value
    },
    setPauseGameTimestamp(value: number) {
      self.pauseGameTimestamp = value
    },
    setMIOutOfGame(value: boolean) {
      self.mIOutOfGame = value
    },
    setIsAnyPlayerGoesOutOfChips(value: boolean) {
      self.isAnyPlayerGoesOutOfChips = value
    },
    setIsGameOver(value: boolean) {
      self.isGameOver = value
    },
    setIsGameDeleted(value: boolean) {
      self.isGameDeleted = value
    },
    setGameId(value: string) {
      self.gameId = value
    },
    setCurrentBlinds(value: NextBlindType) {
      self.currentBlind = value
    },
    setNextBlinds(value: NextBlindType) {
      self.nextBlind = value
    },
    setCurrentBlindTimestamp(value: number) {
      self.currentBlindTimestamp = value
    },
    setPlayerJoinedTimeStamp(value: number) {
      self.playerJoinedTimeStamp = value
    },
    setGameResetTimestamp(value: number) {
      self.gameResetTimestamp = value
    },
    setIsTournamentModeOn(value: boolean) {
      self.isTournamentModeOn = value
    },
    setShouldHighlightCurrentPlayer(value: boolean) {
      self.shouldHighlightCurrentPlayer = value
    },
    setIsCalledForNextBlind(value: boolean) {
      self.isCalledForNextBlind = value
    },
    setStartingChips(value: number) {
      self.startingChips = value
    },
    setActionTime(value: number) {
      self.actionTime = value
    },
    setBlindTime(value: number) {
      self.blindTime = value
    },
    setHasUserDeclinedToBuy(value: boolean) {
      self.hasUserDeclinedToBuy = value
    },
    setRemainingTime(value: number) {
      self.remainingTime = value
    },
    setPauseRemainingTime(value: number) {
      self.pauseRemainingTime = value
    },
    setIsPauseGame(value: boolean) {
      self.isPauseGame = value
    },
    setPauseCounter(value: number) {
      self.pauseCounter = value
    },
    setGameStartTime(value: number) {
      self.gameStartTime = value
    },
    setTournamentId(value: string) {
      self.tournamentId = value
    },
    setLeaderboardData(data: any) {
      self.leaderboardData = data
    },
    setBlindLevel(value: number) {
      self.blindLevel = value
    },
    setIsLeaderboardAccessedFromDrawer(value: boolean) {
      self.isLeaderboardAccessedFromDrawer = value
    },
    setMyRank(value: number) {
      self.myRank = value
    },
    setPlayerActionDuration(value: number) {
      self.playerActionDuration = value
    },
    setIsWinnerInfoDisplayedToOutPlayer(value: boolean) {
      self.isWinnerInfoDisplayedToOutPlayer = value
    },
    setIsLeaderboardDisplayedToOutPlayer(value: boolean) {
      self.isLeaderboardDisplayedToOutPlayer = value
    },
    setHasUserTappedOnAction(value: boolean) {
      self.hasUserTappedOnAction = value
    },
    setLastActiveCheckpoint(value: number) {
      self.lastActiveCheckpoint = value
    },
    setPlayKind(value: string) {
      self.playKind = value
    },
    setPlayers() {
      console.log('session.game setPlayers')
      const userId = self.rootStore.session.user?._id.toString()
      const currentPlayer = self.getcurrentPlayerIndex()
      console.log('session.game setPlayers game?.gameState?.tag', self.game?.gameState?.tag)
      // self.isMyTurn =
      //   !self.isGameReset && self.isPlayingGame && self.myPlayer?.index === self.defaultTable?.currentPlayer
      console.log('session.game setPlayers self.isMyTurn', self.isMyTurn)
      console.log('session.game currentPlayer', currentPlayer)
      console.log('session.game isMyTurn', self.isMyTurn, self.myPlayer?.index, currentPlayer)
      console.log('session.game gamePlayers111', self.gamePlayers)
      const gamePlayers = self.gamePlayers.filter((player: any) => {
        return (
          player.state.tag !== PlayerStates.Observer.tag ||
          (player.state.tag === PlayerStates.Observer.tag && player.observerId && player.observerId === 1)
        )
      })
      // const gamePlayers = self.gamePlayers
      console.log('session.game gamePlayers222', gamePlayers)
      const myPlayerIndex = gamePlayers.findIndex((player: Player) => player.userId === userId)
      if (myPlayerIndex === -1) {
        const list = gamePlayers.map((player: Player, index: number) => {
          return self.createMappedPlayer(player, index)
        })
        self.players.replace(list)
        return
      }
      console.log('session.game myPlayerIndex', myPlayerIndex)
      const createMappedPlayer = (player: any, index: number) => {
        return self.createMappedPlayer(player, index)
      }
      self.myPlayer = PlayerModel.create({ ...createMappedPlayer(gamePlayers[myPlayerIndex], myPlayerIndex) })
      console.log('session.game myPlayer', myPlayerIndex)
      const count = gamePlayers.length
      const isEven = count % 2 === 0
      const bottomLeft = count > 2 && isEven ? count - 2 : count - 1
      const countRotations = bottomLeft - myPlayerIndex
      console.log(
        'session.game bottomLeft - myPlayerIndex',
        count,
        myPlayerIndex,
        'targets',
        bottomLeft,
        'with',
        countRotations,
        'rotations'
      )

      const decrement = (index: number) => {
        const targetIndex = index - 1
        if (targetIndex < 0) {
          return count - 1
        }
        return targetIndex
      }

      const increment = (index: number) => {
        const targetIndex = index + 1
        if (targetIndex > count - 1) {
          return 0
        }
        return targetIndex
      }

      const clockwiseify = (arr: PlayerType[]) => {
        if (count === 0) {
          return arr
        }
        const isLast = bottomLeft === count - 1
        const clone = arr.slice()
        console.log(`session.game reorder from myPlayer players[${myPlayerIndex}] to players[${bottomLeft}]`)
        let countdown = count - 1
        let lastUp = isLast ? myPlayerIndex : increment(myPlayerIndex)
        let lastDown = decrement(lastUp)
        if (isEven) {
          lastUp = decrement(lastUp)
          lastDown = decrement(lastUp)
        }
        while (countdown >= 0) {
          const even = countdown % 2
          const targetIndex = even ? lastDown : lastUp
          console.log('countdown', countdown, 'to', targetIndex, 'last', lastDown, lastUp)
          clone.splice(countdown, 1, {
            ...createMappedPlayer(arr[targetIndex], targetIndex),
            mappedIndex: countdown,
          })
          if (even) {
            lastDown = decrement(targetIndex)
          } else {
            lastUp = increment(targetIndex)
          }
          countdown = countdown - 1
        }
        return clone
      }

      const cwPlayers = clockwiseify(gamePlayers)
      console.log('session.game setPlayers transform', self.gamePlayers, 'to', cwPlayers)
      //NOTE: Getting color in player object, so no need for extra mapping
      self.players.replace(cwPlayers)

      const numberOfPlayersWithNoChips = gamePlayers.reduce((sum: number, player: Player) => {
        if (player.chips < 1) {
          sum = sum + 1
        }
        return sum
      }, 0)

      self.showCardsForAllin = gamePlayers.length - numberOfPlayersWithNoChips === 1
    },
  }))
  .actions((self) => ({
    setGridPreset(gridPreset: GridPreset) {
      self.gridPreset = gridPreset
    },
    addPlayerRanks(playerId: string) {
      if (self.playerRanks.indexOf(playerId) === -1) {
        self.playerRanks.push(playerId)
      }
    },
    addObserverPlayersNumber(playerId: number) {
      if (!self.observerPlayerNumber.includes(playerId)) {
        self.observerPlayerNumber.push(playerId)
      }
    },
    clearPlayerRanks() {
      self.playerRanks.clear()
    },
    clearObserverPlayersNumber() {
      self.observerPlayerNumber.clear()
    },
    clearMyPlayerActions() {
      if (self.rootStore.session.game?.forPlayer?.actions) {
        self.rootStore.session.game.forPlayer.actions = []
      }
    },
    resetBlind() {
      console.log('resetBlindLevel')
      self.setCurrentBlinds({ big: 0, small: 0 })
      self.setNextBlinds({ big: 0, small: 0 })
      self.blindLevel = 0
    },
    clearGame() {
      self.rootStore.session.updateLastAction(undefined)
      // self.setisShowdown(false)
      self.setStartingChips(10000)
      self.players.clear()
      self.winners.clear()
      self.winnerCommunityCards.clear()
      self.modals.clearGameModalQueue()
      self.events.clearGameEvents()
      self.game = undefined
      // self.resetLastPlayerAction()
      self.rootStore.session.closeSocketConnection()
    },
    clearGameEvents() {
      self.events.clearGameEvents()
      self.modals.clearGameModalQueue()
    },
    setAppState(nextAppState: AppStateStatus) {
      self.appState = nextAppState
    },
    setGame(data: GameSnapshotModelType) {
      console.log('session.game setGame', GameSnapshotModel.is(data), data)
      if (GameSnapshotModel.is(data)) {
        console.log('session.game setGame gameState data.gameState', GameSnapshotModel.is(data), data.gameState)
        self.game = data
        const sortedPlayers = orderBy(data.players, 'checkpoint', 'desc')

        const lastActiveCheckpoint = sortedPlayers[0] ? sortedPlayers[0].checkpoint : self.lastActiveCheckpoint
        self.setLastActiveCheckpoint(lastActiveCheckpoint)
        const gameSTartTimestamp = get(self.game, 'table[0].gameStartTime', 0)
        const nextBlindValues = get(self.game, 'table[0].nextBlinds', { big: 0, small: 0 })
        const currentBlindValues = get(self.game, 'table[0].blinds', { big: 0, small: 0 })
        self.setGameStartTime(gameSTartTimestamp)
        if (self.currentBlind.big < currentBlindValues.big && self.currentBlind.small < currentBlindValues.small) {
          self.setCurrentBlinds(currentBlindValues.small === null ? { big: 0, small: 0 } : currentBlindValues)
        }
        if (self.nextBlind.big < nextBlindValues.big && self.nextBlind.small < nextBlindValues.small) {
          self.setNextBlinds(nextBlindValues.small === null ? { big: 0, small: 0 } : nextBlindValues)
        }
        self.setPlayers()
        const gameStateTag = get(self.game, 'gameState.tag', '')
        if ([GameStates.ReadyToPlay.tag].includes(gameStateTag)) {
          console.log('session.game setGame isPlayingGame', self.isPlayingGame)
          console.log('session.game setGame seatedPlayers.length', self.rootStore.session.seatedPlayers.length)
          console.log('session.game setGame players.length', self.rootStore.session.players.length)
          // if (!self.rootStore.session.isGameBegin && self.rootStore.session.seatedPlayers.length > 1) {
          // const isHost = self.rootStore.session.isHost ? true : false
          // if (
          //   self.rootStore.session.isGameBegin === isHost &&
          //   self.rootStore.session.seatedPlayers.length > 1 &&
          //   !self.isPlayingGame
          // ) {
          //   // self.rootStore.session.createGameStartTimerModal()
          // }
        }
      } else {
        MappingError.check(GameSnapshotModel.name, GameSnapshotModel, data)
      }
    },
    convertCardsToReadable(cards: any) {
      // Our return array
      const convertedCards: string[][] = []
      // For each card we make its value and suit readable and add it to convertedCards array
      cards &&
        cards.map((card: any) => {
          let readableVal: string = ''
          let readableSuit: string = ''

          const val = card[0]
          switch (val) {
            case '2':
              readableVal = 'Two'
              break
            case '3':
              readableVal = 'Three'
              break
            case '4':
              readableVal = 'Four'
              break
            case '5':
              readableVal = 'Five'
              break
            case '6':
              readableVal = 'Six'
              break
            case '7':
              readableVal = 'Seven'
              break
            case '8':
              readableVal = 'Eight'
              break
            case '9':
              readableVal = 'Nine'
              break
            case 'T':
              readableVal = 'Ten'
              break
            case 'J':
              readableVal = 'Jack'
              break
            case 'Q':
              readableVal = 'Queen'
              break
            case 'K':
              readableVal = 'King'
              break
            case 'A':
              readableVal = 'Ace'
              break
            default:
              readableVal = ''
          }

          const suit = card[1]
          switch (suit) {
            case 's':
              readableSuit = 'Spades'
              break
            case 'h':
              readableSuit = 'Hearts'
              break
            case 'd':
              readableSuit = 'Diamonds'
              break
            case 'c':
              readableSuit = 'Clubs'
              break
            default:
              readableSuit = ''
          }

          convertedCards.push([readableVal, readableSuit])
        })
      return convertedCards
    },
    unshiftGameWinner() {
      self.winners.unshift()
    },
    getObserverPlayerNumber() {
      return self.observerPlayerNumber
    },
    getReadbleWinCards(
      isWinner: boolean,
      isSplit: boolean,
      holeCards: string[],
      allWinningCards: any[],
      winKind: string
    ) {
      // Our output string
      let readableWinCards = ''

      // We don't need to truly format cards unless they are actually coming from the winning player.
      let formattedWinningCards = allWinningCards
      if (isWinner) {
        formattedWinningCards = allWinningCards.map((card) => `${card.value}${card.suit}`)
      }
      const cards = self.rootStore.session.convertCardsToReadable(formattedWinningCards)

      const winKinds: string[] = [
        translate('gameWinningHand.straight'),
        translate('gameWinningHand.royalFlush'),
        translate('gameWinningHand.straightFlush'),
        translate('gameWinningHand.flush'),
        translate('gameWinningHand.fullHouse'),
        translate('gameWinningHand.highCard'),
        translate('gameWinningHand.pair'),
        translate('gameWinningHand.twoPair'),
        translate('gameWinningHand.threeOfKind'),
        translate('gameWinningHand.fourOfKind'),
      ]

      const highCardOrder: string[] = [
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Jack',
        'Queen',
        'King',
        'Ace',
      ]

      const getHighCard = () => {
        const allIndices: number[] = []
        cards.forEach((card) => {
          const cardIndex = highCardOrder.findIndex((c) => c === card[0])
          allIndices.push(cardIndex)
        })
        // allIndices.sort()  -- Sorts alphabetically.. even though it's a type-safe number array..
        allIndices.sort(function (a, b) {
          return a - b
        })
        return highCardOrder[allIndices[allIndices.length - 1]]
      }

      if (winKinds.includes(winKind)) {
        if (winKind === winKinds[0]) {
          // Straight
          let highCard: string = getHighCard()
          if (highCard !== '') {
            readableWinCards = `${winKinds[0]}, ${highCard} High`
          } else {
            console.warn('CARD PARSE ERROR -- STRAIGHT')
          }
        } else if (winKind === winKinds[1]) {
          // Royal Flush
          let suit: string = cards[0][1]
          if (suit !== '') {
            readableWinCards = `${winKinds[1]}, ${suit}`
          } else {
            console.warn('CARD PARSE ERROR -- ROYAL_FLUSH')
          }
        } else if (winKind === winKinds[2]) {
          // Straight Flush
          let suit: string = cards[0][1]
          if (suit !== '') {
            readableWinCards = `${winKinds[2]}, ${suit}`
          } else {
            console.warn('CARD PARSE ERROR -- STRAIGHT_FLUSH')
          }
        } else if (winKind === winKinds[3]) {
          // Flush
          let highCard: string = getHighCard()
          if (highCard !== '') {
            readableWinCards = `${winKinds[3]}, ${highCard} High`
          } else {
            console.warn('CARD PARSE ERROR -- FLUSH')
          }
        } else if (winKind === winKinds[4]) {
          // Full House
          let threeVal = ''
          let twoVal = ''
          cards.forEach((card) => {
            const valToCheck = card[0]
            const countFound = cards.filter((cardToCheck) => cardToCheck[0] == valToCheck).length
            if (countFound === 3 && threeVal === '') {
              threeVal = card[0]
            } else if (countFound === 2 && twoVal === '') {
              twoVal = card[0]
            }
          })
          if (threeVal !== '' && twoVal !== '') {
            readableWinCards = `${winKinds[4]}, ${threeVal === 'Six' ? 'Sixe' : threeVal}s full of ${
              threeVal === 'Six' ? 'Sixe' : twoVal
            }s`
          } else {
            console.warn('CARD PARSE ERROR -- FULL_HOUSE')
          }
        } else if (winKind === winKinds[5]) {
          // High Card
          let highCard: string = getHighCard()
          if (highCard !== '') {
            readableWinCards = `${winKinds[5]}, ${highCard}`
          } else {
            console.warn('CARD PARSE ERROR -- HIGH_CARD')
          }
        } else if (winKind === winKinds[6]) {
          // Pair
          const pairCardVals: string[] = []
          const alreadySeen: string[] = []
          cards.forEach((card) =>
            alreadySeen.includes(card[0]) ? pairCardVals.push(card[0]) : alreadySeen.push(card[0])
          )
          if (pairCardVals.length === 1) {
            readableWinCards = `${winKinds[6]} of ${pairCardVals[0] === 'Six' ? 'Sixe' : pairCardVals[0]}s`
          } else {
            console.warn('CARD PARSE ERROR -- PAIR')
          }
        } else if (winKind === winKinds[7]) {
          // Two Pair
          const pairCardVals: string[] = []
          const alreadySeen: string[] = []
          cards.forEach((card) =>
            alreadySeen.includes(card[0]) ? pairCardVals.push(card[0]) : alreadySeen.push(card[0])
          )
          if (pairCardVals.length === 2) {
            readableWinCards = `${winKinds[7]}, ${pairCardVals[0] === 'Six' ? 'Sixe' : pairCardVals[0]}s and ${
              pairCardVals[1] === 'Six' ? 'Sixe' : pairCardVals[1]
            }s`
          } else {
            console.warn('CARD PARSE ERROR -- TWO_PAIR')
          }
        } else if (winKind === winKinds[8]) {
          // Three of a Kind
          let val = ''
          cards.forEach((card) => {
            const valToCheck = card[0]
            const countFound = cards.filter((cardToCheck) => cardToCheck[0] == valToCheck).length
            if (countFound === 3) {
              val = card[0]
            }
          })
          if (val !== '') {
            readableWinCards = `${winKinds[8]}, ${val === 'Six' ? 'Sixe' : val}s`
          } else {
            console.warn('CARD PARSE ERROR -- THREE_OF_A_KIND')
          }
        } else if (winKind === winKinds[9]) {
          // Four of a Kind
          let val = ''
          cards.forEach((card) => {
            const valToCheck = card[0]
            const countFound = cards.filter((cardToCheck) => cardToCheck[0] == valToCheck).length
            if (countFound === 4) {
              val = card[0]
            }
          })
          if (val !== '') {
            readableWinCards = `${winKinds[9]}, ${val === 'Six' ? 'Sixe' : val}s`
          } else {
            console.warn('CARD PARSE ERROR -- FOUR_OF_A_KIND')
          }
        }
      } else {
        // Missing Type
        console.warn('#### MISSING TYPE')
        return ''
      }
      return readableWinCards
    },
    setGameWinners(data: GameSnapshotModelType) {
      console.log('setGameWinners', GameSnapshotModel.is(data), data)
      const defaultTable: TableSnapshot = get(data, 'snapshot.table[0]', null)
      const winners: PotWinner[] = get(data, 'snapshot.table[0].winners', [])
      const winnerCommunityCards = get(data, 'snapshot.table[0].communityCards', [])
      const myPlayerId = self.myPlayer?.id ?? -1
      const mainPot = defaultTable?.mainPot ?? 0
      const sidePots = defaultTable?.sidePots ?? []
      self.winnerCommunityCards.replace(winnerCommunityCards)
      self.winners.replace(winners)
      console.log('setGameWinners winners.length', winners.length)
      // Check if sidepot exist in winners
      // const sidepotIndex = self.winners.findIndex((winner) => winner.potKind === 'SIDE')
      // NOTE: side pots and split pots need to be separated
      const sidePotWinners = self.winners.filter((winner) => winner.potKind === 'SIDE')
      const mainPotWinners = self.winners.filter((winner) => winner.potKind === 'MAIN')
      const countMainPotWinners = mainPotWinners.length

      // Announce stayed in the hand and lost
      self.rootStore.session.players.forEach((player) => {
        const playerUserId = player.userId
        const playerHoleCards = player.cards
        const playerRankedHandName = player.player?.rankedHandName
        const playerName = player.name
        if (playerHoleCards && playerRankedHandName) {
          let isWinner = false
          winners.forEach((winner) => {
            if (winner.userId === playerUserId) {
              isWinner = true
            }
          })

          if (!isWinner) {
            const allLoserCards = [...winnerCommunityCards, ...playerHoleCards]
            const loserRankedHandName = self.rootStore.session.getWinningHandDescription(
              playerRankedHandName,
              allLoserCards
            )
            const newLoserRankedHandName = self.rootStore.session.getReadbleWinCards(
              false,
              false,
              playerHoleCards,
              allLoserCards,
              loserRankedHandName
            )
            if (
              player.playerState.tag !== PlayerStates.Folded.tag &&
              player.playerState.tag !== PlayerStates.Out.tag &&
              player.playerState.tag !== PlayerStates.Disconnected.tag &&
              player.playerState.tag !== PlayerStates.ForeverOut.tag &&
              player.playerState.tag !== PlayerStates.Observer.tag &&
              player.playerState.tag !== PlayerStates.Waiting.tag &&
              self.rootStore.session.game?.gameState.tag === GameStates.Playing.tag &&
              mainPotWinners[0].winningCards.length > 0
            ) {
              self.rootStore.session.addChatLocally(
                self.rootStore.session.channel?.channelName || 'channelNameUndefined',
                playerName || 'userNameUndefined',
                `${playerName} has ${newLoserRankedHandName}.`,
                ChatKind.dealer,
                'white'
              )
            }
          }
        }
      })

      // Announce who stayed in the hand and who won
      if (countMainPotWinners > 0 && mainPotWinners[0]) {
        const winner = mainPotWinners[0]
        const winningHand =
          winner.winningCards.length > 0
            ? winner.winningCards.map((winningCard) => `${winningCard.value}${winningCard.suit}`)
            : []
        let winText = myPlayerId === winner.playerId ? 'you won main pot' : `${winner.playerName} wins main pot`
        // if only one winner, show the winning hand
        if (countMainPotWinners > 1) {
          // else if we have multiple winners, we split the pot!
          // NOTE: Put 'and' between the players list as per grammer
          const playerNames = mainPotWinners.reduce((message, winner, index) => {
            const playerName = winner.playerName
            if (index === 0) {
              return playerName
            } else if (index === countMainPotWinners - 1) {
              return `${message} and ${playerName}`
            }
            return `${message}, ${playerName}`
          }, '')
          const translatedSplitPotWinningMessage = translate('common.sidepotPlayers', {
            playerNames,
          })
          // translatedSplitPotWinningMessage && self.rootStore.session.createSidepotPlayer(translatedSplitPotWinningMessage)
          if (translatedSplitPotWinningMessage) {
            winText = translatedSplitPotWinningMessage
          }
        }
        if (winner.winningCards.length) {
          const sevenWinnerCommunityCards = [...winnerCommunityCards, ...winner.cards]
          const rankedHandName = self.rootStore.session.getWinningHandDescription(
            winner.rankedHandName,
            sevenWinnerCommunityCards
          )
          const winningStatus = `${winText} with ${rankedHandName}!`.toUpperCase()
          self.rootStore.session.createWinningHandModal(
            data,
            GameWinner.create({
              winningText: winningStatus,
              chipsWon: winner.chipsWon,
              winningCards: winningHand,
              holeCards: winner.cards,
              mainPot: mainPot,
              sidePot: sidePots,
            })
          )
          if (!winText.includes('split')) {
            const newRankedHandName = self.rootStore.session.getReadbleWinCards(
              true,
              false,
              winner.cards,
              winner.winningCards,
              rankedHandName
            )

            self.rootStore.session.addChatLocally(
              self.rootStore.session.channel?.channelName || 'channelNameUndefined',
              winner.playerName,
              `${winner.playerName} ${translate('chatDealerMessages.playerWonWith')} ${newRankedHandName}!`,
              ChatKind.dealer,
              'white'
            )
          } else {
            self.rootStore.session.addChatLocally(
              self.rootStore.session.channel?.channelName || 'channelNameUndefined',
              winner.playerName,
              `${winText} with ${rankedHandName}!`,
              ChatKind.dealer,
              'white'
            )
          }
        } else {
          const winningStatus = `${winText} everyone folded!`.toUpperCase()
          self.rootStore.session.createWinningHandModal(
            data,
            GameWinner.create({
              winningText: winningStatus,
              chipsWon: winner.chipsWon,
              winningCards: [],
              holeCards: [],
              mainPot: mainPot,
              sidePot: sidePots,
            })
          )
          self.rootStore.session.addChatLocally(
            self.rootStore.session.channel?.channelName || 'channelNameUndefined',
            winner.playerName,
            `${winner.playerName} ${translate('chatDealerMessages.playerWon')}, everyone folded!`,
            ChatKind.dealer,
            'white'
          )
        }
      }

      // NOTE: check if same winner won the side pots, then skip side pot modals (we show only the winning modal)
      // const sameWinner = isEqualWith(mainPotWinners, sidePotWinners, (a, b) => a.playerId === b.playerId)
      // NOTE: sameWinner do not open side pot winner modals
      // if (!sameWinner) {
      // @TODO: we can have split pots for side pots... not sure how to determine
      sidePotWinners.forEach((winner, index) => {
        const winningHand =
          winner.winningCards.length > 0
            ? winner.winningCards.map((winningCard) => `${winningCard.value}${winningCard.suit}`)
            : []
        const winText = myPlayerId === winner.playerId ? 'you won side pot' : `${winner.playerName} wins side pot`
        if (winner.winningCards.length) {
          const rankedHandName = winner.rankedHandName.toLowerCase() === 'pair' ? 'a pair' : winner.rankedHandName
          const winningStatus = `${winText} with ${rankedHandName}!`.toUpperCase()
          self.rootStore.session.createWinningHandModal(
            data,
            GameWinner.create({
              winningText: winningStatus,
              chipsWon: winner.chipsWon,
              winningCards: winningHand,
              holeCards: winner.cards,
              mainPot: mainPot,
              sidePot: sidePots,
            })
          )
          self.rootStore.session.addChatLocally(
            self.rootStore.session.channel?.channelName || 'channelNameUndefined',
            winner.playerName,
            `${winner.playerName} has won the side pot with ${rankedHandName}!`,
            ChatKind.dealer,
            'white'
          )
        } else {
          const winningStatus = `${winText} everyone folded!`.toUpperCase()
          self.rootStore.session.createWinningHandModal(
            data,
            GameWinner.create({
              winningText: winningStatus,
              chipsWon: winner.chipsWon,
              winningCards: [],
              holeCards: [],
              mainPot: mainPot,
              sidePot: sidePots,
            })
          )
          self.rootStore.session.addChatLocally(
            self.rootStore.session.channel?.channelName || 'channelNameUndefined',
            winner.playerName,
            `${winner.playerName} wins side pot, everyone folded!`,
            ChatKind.dealer,
            'white'
          )
        }
      })
      // }
      self.resetLastPlayerAction()
    },
    clearGameWinners() {
      self.winners.clear()
      // self.resetLastPlayerAction()
    },
    // Player events AllIn = 'poker:player:allin', and updates state to AllIn
    // Table events PlayerAllIn = 'poker:table:player:allin', and  sends a for player snapshot of the all in player
    // Player bet amount will be all of their chips
    // Each subsequent player will receive bet actions for fold, call, or allin  depending on their chips and if they can
    // Players who subsequently go allin create sidepots -> winners are evaluated pot by pot
    setGameAllIn(data: GameSnapshotModelType) {
      console.log('setGameAllIn', GameSnapshotModel.is(data), data)
      const playerUserId = get(data, 'snapshot.forPlayer.userId', '')
      const userId = self.rootStore.session.user?._id
      const isMe = playerUserId === userId
      const firstName = get(data, 'snapshot.forPlayer.name', 'PLAYER')
      self.allIn = isMe ? 'YOU ARE ALL IN!' : `${firstName} IS ALL IN!`
      self.rootStore.session.addChatLocally(
        self.rootStore.session.channel?.channelName || 'channelNameUndefined',
        firstName,
        `${firstName} ${translate('chatDealerMessages.playerAllIn')}`,
        ChatKind.dealer,
        'white'
      )
      // self.rootStore.session.createAllInModal()
    },
    clearAllIn() {
      self.allIn = ''
    },
  }))
  .actions((self) => ({
    async createGame(newChannelName?: string) {
      const channelName = newChannelName || self.rootStore.session.channel?.channelName
      const userId = self.rootStore.session.user?._id
      console.log('createGame channelName', channelName)
      console.log('createGame userId', userId)
      if (channelName && userId) {
        try {
          self.clearGame()
          const command: GameCommand = {
            channelName,
            userId: userId,
            kind: self.isTournamentModeOn ? GameKind.tournament : GameKind.default,
          }
          console.log('createGame mutateCreateGame', command)
          const response = await self.rootStore.api.mutateCreateGame({
            command,
          })
          console.log('mutateCreateGame response', response.createGame)
          self.setGameId(response.createGame._id)
          self.rootStore.session.setGame(response.createGame.snapshot)
        } catch (error) {
          console.error('mutateCreateGame', error)
          // throw error
        }
      }
    },
    async joinGame(shouldSyncGameState?: boolean) {
      console.log('joinGame shouldSyncGameState', shouldSyncGameState)
      const channelName = self.rootStore.session.channel?.channelName
      const userId = self.rootStore.session.user?._id.toString()
      const playerName = self.rootStore.session.user?.name
      const playerKind = self.rootStore.session.playKind

      console.log('joinGame channelName', channelName)
      console.log('joinGame userId', userId)
      console.log('joinGame playerName', playerName)
      if (channelName && userId && playerName) {
        try {
          const response = await self.rootStore.api.mutateJoinGame({
            command: {
              channelName,
              userId,
              playerName,
              startingChips: self.startingChips,
              playKind: playerKind === 'observer' ? PlayKind.observe : PlayKind.play,
            },
          })
          console.log('joinGame', response.joinGame)
          self.setGameId(response.joinGame._id)
          console.log('joinGame players', response.joinGame.snapshot.players.length)
          // const gameTimestamp = Date.parse(response.joinGame.createdAt)
          // self.setGameTimestamp(gameTimestamp)
          if (shouldSyncGameState) {
            self.rootStore.session.setGame(response.joinGame.snapshot)
          }
        } catch (error) {
          self.rootStore.session.clearGameModalQueue()
          RootNavigation.navigate(DrawerRoutes.lobby)
          // throw error
        }
      }
    },
    unsubscribeOnGameDelete() {
      self.rootStore.session.teardown()
    },
    getplayerTotalChipCount(chipvalue: any, betvalue: any) {
      if (chipvalue && betvalue) {
        return chipvalue + betvalue
      } else if (chipvalue) {
        return chipvalue
      } else if (betvalue) {
        return betvalue
      } else {
        return -1
      }
    },
    async doAllInForPlayer() {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('session.game doAllInForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: TableCommand = {
              channelName,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoAllInForPlayer({ command })
            console.log('session.game doAllInForPlayer', response.doAllInForPlayer)
            // self.setGame(response.doAllInForPlayer.snapshot)
            // if (response.doAllInForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doAllInForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doAllInForPlayer error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async doBetForPlayer(amount: number, kind: BetKind) {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        const me = self.players.find((player) => player.userId === userId)
        console.log('session.game doBetForPlayer', channelName, userId, self.players.length, me)
        if (me) {
          const playerId = me.id
          console.log('session.game doBetForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: BetCommand = {
              channelName,
              bet: {
                amount,
                kind,
              },
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoBetForPlayer({ command })
            const chatBetType =
              command.bet.kind === BetKind.BET
                ? translate('chatDealerMessages.playerBet')
                : translate('chatDealerMessages.playerRaised')
            self.rootStore.session.sendChat(
              `${self.rootStore.session.user?.name} ${chatBetType} ${amount}.`,
              ChatKind.dealer,
              'white'
            )
            console.log('session.game doBetForPlayer', response.doBetForPlayer)
            // self.setGame(response.doBetForPlayer.snapshot)
            // if (response.doBetForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doBetForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doBetForPlayer error', error)
        throw error
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async doBuyForPlayer(amount: number) {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        const me = self.players.find((player) => player.userId === userId)
        console.log('session.game doBuyForPlayer', channelName, userId, self.players.length, me)
        if (me) {
          const playerId = me.id
          console.log('session.game doBuyForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: BuyCommand = {
              channelName,
              amount,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoBuyForPlayer({ command })
            console.log('session.game doBuyForPlayer', response.doBuyForPlayer)
            // self.setGame(response.doBuyForPlayer.snapshot)
            // if (response.doBuyForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doBuyForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doBuyForPlayer error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async doCallForPlayer() {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('session.game doCallForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: TableCommand = {
              channelName,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoCallForPlayer({ command })
            console.log('session.game doCallForPlayer', response.doCallForPlayer)
            self.rootStore.session.sendChat(
              `${self.rootStore.session.user?.name} ${translate('chatDealerMessages.playerCalled')}`,
              ChatKind.dealer,
              'white'
            )
            // self.setGame(response.doCallForPlayer.snapshot)
            // if (response.doCallForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doCallForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doCallForPlayer error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async doCheckForPlayer() {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('session.game doCheckForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: TableCommand = {
              channelName,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoCheckForPlayer({ command })
            console.log('session.game doCheckForPlayer', response.doCheckForPlayer)
            self.rootStore.session.sendChat(
              `${self.rootStore.session.user?.name} ${translate('chatDealerMessages.playerChecked')}`,
              ChatKind.dealer,
              'white'
            )
            // self.setGame(response.doCheckForPlayer.snapshot)
            // if (response.doCheckForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doCheckForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doCheckForPlayer error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async doFoldForPlayer() {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('session.game doFoldForPlayer for channel', channelName)
          if (channelName && userId) {
            const command: TableCommand = {
              channelName,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateDoFoldForPlayer({ command })
            console.log('session.game doFoldForPlayer', response.doFoldForPlayer)
            self.rootStore.session.sendChat(
              `${self.rootStore.session.user?.name} ${translate('chatDealerMessages.playerFolded')}`,
              ChatKind.dealer,
              'white'
            )
            // self.setGame(response.doFoldForPlayer.snapshot)
            // if (response.doFoldForPlayer?.messages) {
            //   self.rootStore.session.events.pushGameEvents(response.doFoldForPlayer?.messages)
            // }
          }
        }
      } catch (error) {
        console.error('session.game doFoldForPlayer error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async setPlayerBuyIn() {
      try {
        self.setHasUserTappedOnAction(true)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('session.game setPlayerBuyIn for channel', channelName)
          if (channelName && userId) {
            const command: TableCommand = {
              channelName,
              playerId,
              userId,
            }
            const response = await self.rootStore.api.mutateSetPlayerBuyIn({ command })
            console.log('session.game setPlayerBuyIn', JSON.stringify(response.setPlayerBuyIn))
          }
        }
      } catch (error) {
        console.error('session.game setPlayerBuyIn error', error)
      } finally {
        // We add a timeout so buttons no longer flash after we submit our player action.
        setTimeout(() => {
          self.setHasUserTappedOnAction(false)
        }, 750)
      }
    },
    async wakeUp() {
      try {
        console.log('session-game afterCreate wake the up lambdas')
        const response = await self.rootStore.api.queryHello()
        console.log('session-game afterCreate queryHello', response)
      } catch (error) {
        console.error('session-game afterCreate', error)
      }
    },
    teardown() {
      self.rootStore.session.clearObserverPlayersNumber()
      self.rootStore.session.clearAllIn()
      self.rootStore.session.clearChannel()
      self.rootStore.session.clearGame()
      self.rootStore.session.clearGameModalQueue()
      self.rootStore.session.clearGameWinners()
      self.rootStore.session.clearMessages()
      self.rootStore.session.clearChannelChats()
      self.rootStore.session.clearShare()
      self.rootStore.session.clearLinkingUrl()
      self.rootStore.session.resetTimer()
      self.rootStore.session.forceClearModalQueue()
      self.rootStore.session.setActionTime(defaultActionTime)
      self.rootStore.session.resetBlind()
      //NOTE: Default time for quick play is 60sec
      self.rootStore.session.setActionTime(60000)
      self.rootStore.session.setIsCardsVisible(false)
      self.rootStore.session.setIsWinnerInfoDisplayedToOutPlayer(false)
      self.rootStore.session.clearPlayerRanks()
      self.rootStore.session.hideCardsForTeacher()
      self.rootStore.session.setPlayKind('')
      console.log('session-game teardown()')
      self.rootStore.session.setIsTournamentModeOn(false)
      self.rootStore.session.setPlayerJoinedTimeStamp(0)
      self.rootStore.session.endCall()
      self.rootStore.session.setIsLeaderboardDisplayedToOutPlayer(false)
      self.rootStore.session.setHasUserPreFold(false)
      self.rootStore.session.setIsGameOver(false)
      self.rootStore.session.setIsGameDeleted(false)
      self.rootStore.session.clearSnack()
      self.rootStore.telemetry.setUser({ ...self.rootStore.session.user, gameId: undefined })
    },
    closeSocketConnection() {
      try {
        const env = getEnv<RootStoreEnv>(self)
        const manager = env.gqlWsClient
        console.log('doLeaveGame force socket to close...')
        // unsubsribe all GraphQL Subscriptions:
        self.rootStore.session.unsubscribeAll()
        // close client socket
        manager.close(true, true)
        console.log('doLeaveGame force socket to closed')
      } catch (error) {
        console.error('Error in doLeaveGame socket.close', error)
      }
    },
    async leaveCurrentGame() {
      const userId = self.rootStore.session.user?._id.toString()
      const channelName = self.rootStore.session.channel?.channelName
      if (self.myPlayer) {
        const playerId = self.myPlayer.id
        console.log('leaveCurrentGame', channelName, playerId, userId)
        self.rootStore.session.setEvent(events.game.leave, {
          playerName: self.rootStore.session.user?.name,
          channelName,
          userId,
        })
        if (channelName && userId) {
          try {
            // self.setIsTournamentModeOn(false)
            // Player should be connected to channel in tournament mode
            // if (self.isTournamentModeOn === false) {
            await self.rootStore.api.mutateLeaveChannel({ channelName, userId })
            // }
          } catch (error) {
            console.error('doLeaveGame mutateLeaveChannel', error)
          }
          try {
            // if (self.isTournamentModeOn === false) {
            self.rootStore.session.teardown()
            // }
            const response = await self.rootStore.api.mutateDoLeaveGameForPlayer({
              command: {
                channelName,
                userId,
                playerId,
              },
            })
            console.log('doLeaveGame mutateDoLeaveGameForPlayer response: ', response)
            // NOTE: no need to update snapshot (we are leaving)
            // self.setGame(response.doLeaveGameForPlayer.snapshot)
            // if (self.isTournamentModeOn === false) {
            self.rootStore.session.endCall()
            self.rootStore.session.closeSocketConnection()
            self.rootStore.session.resetTimer()
            self.rootStore.session.setPlayKind('')
            // }
          } catch (error) {
            console.error('doLeaveGame mutateDoLeaveGameForPlayer', error)
            throw error
          }
        }
      }
    },
    async removePlayerFromGame() {
      try {
        const channelName = self.rootStore.session.channel?.channelName
        const userId = self.playerUserIdForTeacher
        const user = self.seatedPlayers.filter((player) => player.userId === userId)
        const playerId = user[0].id
        if (channelName && playerId) {
          const response = await self.rootStore.api.mutateRemovedPlayerByTeacher({
            command: {
              channelName,
              userId,
              playerId,
            },
          })
          self.rootStore.session.setGame(response.removedPlayerByTeacher.snapshot)
          console.log('Remove Player: ', response)
        }
      } catch (error) {
        console.error('Error in removing player from Game', error)
      }
    },
    async unseatPlayer() {
      const channelName = self.rootStore.session.channel?.channelName
      const userId = self.playerUserIdForTeacher
      const playerData = self.seatedPlayers.filter((player) => player.userId === userId)
      if (playerData) {
        const playerId = playerData[0].id
        console.log('unseatPlayer', channelName, playerId, userId)
        if (channelName && userId) {
          try {
            const response = await self.rootStore.api.mutateUnseatPlayerByTeacher({
              command: {
                channelName,
                userId,
                playerId,
              },
            })
            console.log('unseatPlayer Success response: ', response)
          } catch (error) {
            console.error('unseatPlayer API Error: ', error)
            throw error
          }
        }
      }
    },
    async seatBackPlayerInGame() {
      const channelName = self.rootStore.session.channel?.channelName
      const userId = self.playerUserIdForTeacher
      const playerData = self.seatedPlayers.filter((player) => player.userId === userId)
      if (playerData) {
        const playerId = playerData[0].id
        console.log('SeatBack Player', channelName, playerId, userId)
        if (channelName && userId) {
          try {
            const response = await self.rootStore.api.mutateSeatedPlayerByTeacher({
              command: {
                channelName,
                userId,
                playerId,
              },
            })
            console.log('SeatBack Player Success response: ', response)
          } catch (error) {
            console.error('SeatBack Player API Error: ', error)
            throw error
          }
        }
      }
    },
    getWinningHandDescription(handDescription: string, sevenWinnerCommunityCards: string[]) {
      switch (handDescription.toLowerCase()) {
        case 'straight':
          return translate('gameWinningHand.straight')
        case 'straight flush':
          const totalRoyalCards = [
            ['td', 'jd', 'qd', 'kd', 'ad'],
            ['th', 'jh', 'qh', 'kh', 'ah'],
            ['ts', 'js', 'qs', 'ks', 'as'],
            ['tc', 'jc', 'qc', 'kc', 'ac'],
          ]
          const isRoyalFlush = totalRoyalCards.map((subarray) => {
            const result = self.rootStore.session.checkRoyalFlushCards(subarray, sevenWinnerCommunityCards)
            return result
          })
          if (isRoyalFlush.includes(true)) {
            return translate('gameWinningHand.royalFlush')
          } else {
            return translate('gameWinningHand.straightFlush')
          }

        case 'flush':
          return translate('gameWinningHand.flush')
        case 'royal flush':
          return translate('gameWinningHand.royalFlush')

        case 'full house':
          return translate('gameWinningHand.fullHouse')

        case 'high card':
          return translate('gameWinningHand.highCard')

        case 'pair':
          return translate('gameWinningHand.pair')
        case 'two pair':
          return translate('gameWinningHand.twoPair')

        case 'three of a kind':
          return translate('gameWinningHand.threeOfKind')
        case 'four of a kind':
          return translate('gameWinningHand.fourOfKind')

        default:
          return handDescription
      }
    },
    checkRoyalFlushCards(royalCards: string[], sevenWinnerCommunityCards: string[]) {
      return royalCards.every((element: any) => {
        if (sevenWinnerCommunityCards.includes(element)) {
          return true
        }
        return false
      })
    },
  }))
  .actions((self) => ({
    async getAllGames(pageNumber: number): Promise<GameEntityModelType[]> {
      try {
        const response = await self.rootStore.api.queryGetAllGames({ page: pageNumber }, { fetchPolicy: 'no-cache' })
        console.log('Get all games from session', response.getAllGames)
        return JSON.parse(response.getAllGames)
      } catch (error) {
        console.error('session.getAllGames error', error)
        return []
      }
    },
    async noActionPerformed() {
      try {
        console.info('No Action called...')
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        const currentPlayerId = self.defaultTable?.currentPlayerId
        if (channelName && userId && currentPlayerId) {
          const isTimeExpired = self.lastActiveCheckpoint + self.playerJoinedTimeStamp
          const currentCheckpoint = new Date().getTime()
          self.rootStore.session.setPlayerJoinedTimeStamp(0)
          if (currentCheckpoint >= isTimeExpired) {
            const response = await self.rootStore.api.mutateDoNoActionForPlayer({
              command: {
                channelName,
                userId,
                playerId: currentPlayerId,
              },
            })
            console.info('No Action performed: ', JSON.stringify(response))
          } else {
            console.log('No action called before time elapses')
          }
          // now is a good time to checkin on the GraphQL Subscription and underlying WebSocket Connection
          // self.rootStore.session.createChannelSubscription(true)
          // self.rootStore.session.createChannelSubscription(false)
        }
      } catch (error) {
        console.error('No Action error', error)
        throw error
      }
    },
    async pauseTheGame() {
      try {
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('Game Pause', channelName, playerId, userId)
          if (channelName && userId) {
            try {
              const response = await self.rootStore.api.mutatePauseGame({
                command: {
                  channelName,
                  userId,
                },
              })
              console.log('Game pause Success response: ', response)
            } catch (error) {
              console.error('Game pause API Error: ', error)
              throw error
            }
          }
        }
      } catch (error) {
        console.error('Pause Game API Error: ', error)
        throw error
      }
    },
    async resumeTheGame() {
      try {
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        if (self.myPlayer) {
          const playerId = self.myPlayer.id
          console.log('Game Resume', channelName, playerId, userId)
          if (channelName && userId) {
            try {
              const response = await self.rootStore.api.mutateResumeGame({
                command: {
                  channelName,
                  userId,
                },
              })
              console.log('Resume Game Success response: ', response)
            } catch (error) {
              console.error('Resume Game API Error: ', error)
              throw error
            }
          }
        }
      } catch (error) {
        console.error('Resume Game API Error: ', error)
        throw error
      }
    },
    async startGame() {
      try {
        console.log('## Start Game is called')
        console.log('startGame isGameAutostartEnabled', self.isGameAutostartEnabled)
        const userId = self.rootStore.session.user?._id.toString()
        const channelName = self.rootStore.session.channel?.channelName
        console.log('startGame', userId, channelName, self.blindTime / 60)
        if (channelName && userId) {
          const response = await self.rootStore.api.mutateStartGame({
            command: {
              channelName,
              userId,
              // @TODO: Switch default to false
              enableAutoStart: self.isTournamentModeOn ? true : self.isGameAutostartEnabled,
              // enableAutoStart: true,
              options: {
                blindTimerCountdown: self.blindTime / 60,
                mode: self.isTournamentModeOn ? GameModes.Tournament.tag : GameModes.Default.tag,
              },
            },
          })
          // self.setIsAnyPlayerGoesOutOfChips(false)
          // console.log('startGame response: ', response)
          console.log('startGame response: response.startGame', response.startGame)
          console.log(
            'startGame response: response.startGame.snapshot.gameState',
            response.startGame.snapshot.gameState
          )

          if (!self.isGameAutostartEnabled && response.startGame.snapshot.gameState === GameStates.Reset.tag) {
            self.rootStore.session.joinGame(true)
          }
          // self.setGame(response.startGame.snapshot)
          // const shareUserId = self.rootStore.session.share?.userId ?? 'share'
          // const isHost = userId === shareUserId
          // if (isHost) {
          //   const response = await self.rootStore.api.mutateNewHand({
          //     command: {
          //       channelName,
          //       userId,
          //     },
          //   })
          //   console.log('startGame response: ', response)
          // }
        }
      } catch (error) {
        console.error('startGame Error', error)
        throw error
      }
    },
    async createTournament(tournamentName: string, blindTimer?: number, actionTime?: number, startingChips?: number) {
      try {
        const payload = {
          name: tournamentName,
          type: TournamentKind.singleTable,
          startTime: new Date(),
          gameIds: [self.gameId],
          endTime: new Date(),
          blindTimer: blindTimer ? blindTimer : 5,
          startingChips: startingChips ? startingChips : 10000,
          actionTime: actionTime ? actionTime : 30,
        }
        const response = await self.rootStore.api.mutateCreateTournament(payload)
        console.log('Create tournament success: ', response)
      } catch (error) {
        console.log('Error in creating tournament', error)
        throw error
      }
    },
    updateLastAction(data: Bet | undefined) {
      console.log('updateLastAction', data)
      self.lastAction = data
    },
    async updateGame(autostart: boolean) {
      try {
        const channelName = self.rootStore.session.channel?.channelName
        if (channelName) {
          const response = await self.rootStore.api.mutateUpdateGame({ autostart, channelName })
          self.setIsGameAutostartEnabled(autostart)
          console.log('Update Game Autostart response: ', response)
        }
      } catch (error) {
        console.log('Update Game autostart error', error)
      }
    },
    async handleAppStateChangeForGame(nextAppState: AppStateStatus) {
      try {
        if (self.appState.match(/inactive|background/) && nextAppState === 'active') {
          console.log('session-game app has come to the foreground!')
          try {
            await self.rootStore.session.refreshingJwtToken()
            await self.rootStore.session.forceWSConnect(true)
            await self.rootStore.permissions.refresh()
          } catch (error) {
            console.error('session-game AppState handleAppStateChangeForGame error', error)
          }
        }
        self.setAppState(nextAppState)
        console.log('session-game AppState changed', self.appState)
      } catch (error) {
        console.error('session-game', error)
      }
    },
    async showCardsForTeacher() {
      const userId = self.rootStore.session.user?._id.toString()
      const channelName = self.rootStore.session.channel?.channelName
      // if (self.myPlayer) {
      // const playerId = self.myPlayer.id
      console.log('showCardsForTeacher', channelName, userId)
      if (channelName && userId) {
        try {
          const response = await self.rootStore.api.mutateShowCardsForTeacher({
            command: {
              channelName,
              userId,
            },
          })
          console.log('showCardsForTeacher Success response: ', JSON.stringify(response))
          self.setIsCardsVisible(true)
        } catch (error) {
          console.error('showCardsForTeacher API Error: ', error)
          throw error
        }
      }
      // }
    },
    async hideCardsForTeacher() {
      const userId = self.rootStore.session.user?._id.toString()
      const channelName = self.rootStore.session.channel?.channelName
      // if (self.myPlayer) {
      // const playerId = self.myPlayer.id
      console.log('hideCardsForTeacher', channelName, userId)
      if (channelName && userId) {
        try {
          const response = await self.rootStore.api.mutateHideCardsForTeacher({
            command: {
              channelName,
              userId,
            },
          })
          console.log('hideCardsForTeacher Success response: ', JSON.stringify(response))
          self.setIsCardsVisible(false)
        } catch (error) {
          console.error('hideCardsForTeacher API Error: ', error)
          throw error
        }
      }
      // }
    },
    async getPlayerStats(gameKey?: string) {
      const userId = self.rootStore.session.user?._id.toString()
      const gameId = gameKey ? gameKey : self.rootStore.session.game?.gameId
      console.log('Game Key: ', gameKey, userId)
      if (gameId && userId) {
        try {
          const response = await self.rootStore.api.queryGetPlayerStats(
            { gameId, userId },
            '_id bestHandWon {rankedHandName createdAt} allTimeBestHandWon {rankedHandName createdAt} numberOfHands numberOfHandsPlayed numberOfHandsWon biggestWin {chipsWon createdAt} allTimeBiggestWin {chipsWon createdAt} preflopFold createdAt',
            { fetchPolicy: 'no-cache' }
          )
          console.log('Player Stats: ', response)
          self.setMyPlayerStats(response.getPlayerStats)
        } catch (error) {
          console.error('getPlayerStats API Error: ', error)
          throw error
        }
      }
    },
    async getPlayerGlobalStats() {
      const userId = self.rootStore.session.user?._id.toString()
      if (userId) {
        try {
          const response = await self.rootStore.api.queryGetPlayerGlobalStats(
            { userId },
            '_id bestHandWon {rankedHandName createdAt} allTimeBestHandWon {rankedHandName createdAt} numberOfHands numberOfHandsPlayed numberOfHandsWon biggestWin {chipsWon createdAt} allTimeBiggestWin {chipsWon createdAt} preflopFold createdAt',
            { fetchPolicy: 'no-cache' }
          )
          console.log('Global Player Stats: ', response)
          self.setMyPlayerStats(response.getPlayerGlobalStats)
        } catch (error) {
          console.error('getPlayerGlobalStats API Error: ', error)
          throw error
        }
      }
    },
    async getPlayerGames() {
      const userId = self.rootStore.session.user?._id.toString()
      if (userId) {
        try {
          const response: any = await self.rootStore.api.queryGetPlayerGames({ userId }, { fetchPolicy: 'no-cache' })
          console.log('getPlayerGames success: ', response)
          return response.getPlayerGames
        } catch (error) {
          console.error('getPlayerGames API Error: ', error)
          throw error
        }
      }
    },
    async getActiveTournament() {
      try {
        const response: any = await self.rootStore.api.queryGetTournaments(
          {},
          '_id color name startingChips actionTime blindTimer isLocked gameIds {channelName forPlayers snapshot _id}',
          { fetchPolicy: 'no-cache' }
        )
        return response.getTournaments
      } catch (error) {
        console.error('getActiveTournament API Error: ', error)
        throw error
      }
    },
    async getTournamentPlayerRank() {
      try {
        const userId = self.rootStore.session.user?._id.toString()
        if (userId) {
          const payload = {
            tournamentId: self.tournamentId,
            userId,
          }
          const response: any = await self.rootStore.api.queryGetTournamentPlayerRank(payload, {
            fetchPolicy: 'no-cache',
          })
          console.log('getTournamentPlayerRank success: ', response.getTournamentPlayerRank)
          // return response.getTournamentPlayerRank
          self.setMyRank(response.getTournamentPlayerRank)
          if (self.rootStore.session.myRank > 0 && !self.rootStore.session.isWinnerInfoDisplayedToOutPlayer) {
            console.log('self.rootStore.session.myRank', self.rootStore.session.myRank)
            if (self.isTournamentModeOn) {
              self.rootStore.session.setIsWinnerInfoDisplayedToOutPlayer(true)
              self.rootStore.session.createPlayerOutOfChipsModal()
            }
          }
        }
      } catch (error) {
        console.error('getTournamentPlayerRank API Error: ', error)
        throw error
      }
    },

    async getTournamentStats(value: boolean) {
      if (self.rootStore.session.isTournamentModeOn) {
        const tournamentId = self.tournamentId
        try {
          const response = await self.rootStore.api.queryGetTournamentStats(
            { tournamentId },
            '_id prizePool players {playerName playerId stack chips isLeft} runTime winners isGameRunning',
            { fetchPolicy: 'no-cache' }
          )
          console.log('Tournament Stats ', response)
          self.setLeaderboardData(response.getTournamentStats)
          requestAnimationFrame(() => {
            self.rootStore.session.createLeaderBoard(value)
          })
        } catch (error) {
          console.log('Error in fetching the tournament stats', error)
        }
      }
    },

    setIsFirstTime(value: boolean) {
      self.isFirstTime = value
    },

    resetTimer() {
      clearInterval(interval)
    },

    millisToMinutesAndSeconds(millis: number) {
      const seconds = Math.floor((millis / 1000) % 60)
      const minutes = Math.floor((millis / (1000 * 60)) % 60)
      return seconds + minutes * 60
    },

    async startBlindTimer() {
      this.resetTimer()
      const ms = new Date()
      const stime = self.gameStartTime // gameStartTimestamp
      const ctime = ms.getTime()
      const dtime = ctime - stime
      const blindTimeDuration = self.blindTime
      let StartClockTime = 0
      let secondsLeft = this.millisToMinutesAndSeconds(dtime)
      let blindIndex: number = Math.floor(secondsLeft / blindTimeDuration)

      // if (self.isFirstTime == false) {
      //   blindIndex = (secondsLeft / blindTimeDuration)
      // }
      if (blindIndex > 0) {
        secondsLeft = secondsLeft - blindTimeDuration * blindIndex
      }
      StartClockTime = StartClockTime + secondsLeft

      {
        /* To Handle Play Pause 
      if (blindIndex > 0) {
        secondsLeft = secondsLeft - blindTimeDuration * blindIndex
      } else if (blindIndex === 0) {
        self.rootStore.session.setPauseRemainingTime(0)
      }

      if (self.pauseCounter === 0) {
        StartClockTime = Math.abs(StartClockTime + secondsLeft) 
      } else if (self.pauseCounter === 1) {
        self.rootStore.session.setPauseCounter(0)
        StartClockTime = 0
      }
    */
      }

      self.rootStore.session.setIsFirstTime(false)
      self.rootStore.session.setRemainingTime(blindTimeDuration)
      self.rootStore.session.setPauseRemainingTime(0)
      interval = setInterval(async () => {
        if (!self.isGamePaused) {
          if (Number(self.nextBlind.small) === 0) {
            self.rootStore.session.setRemainingTime(self.blindTime)
          }
          let remaingTimeInSec = Math.abs(Math.max(0, blindTimeDuration - StartClockTime))
          self.rootStore.session.setRemainingTime(remaingTimeInSec)

          if (remaingTimeInSec === 0 && StartClockTime != 0) {
            this.resetTimer()
            StartClockTime = 0
            try {
              await self.rootStore.session.getNextBlinds()
              if (Number(self.nextBlind.small) !== 0) {
                self.rootStore.session.setRemainingTime(blindTimeDuration)
                this.startBlindTimer()
              }
            } catch (error) {}
          } else {
            StartClockTime = StartClockTime + 1
            // if(self.isGameOver) {
            //   self.rootStore.session.resetBlindLevel()
            // }
          }
        }
      }, 1000)
    },
  }))
  .actions((self) => ({
    async setupGame(navigation: any) {
      console.log('setupGame')
      const store = self.rootStore
      console.log('setupGame store.network.isWebSocketConnected', store.network.isWebSocketConnected)
      // if (store.network.isWebSocketConnected) {
      //   return
      // }

      const checkConnection = async () => {
        try {
          console.log(
            'setupGame GameScreen.useEffect store.network.isWebSocketConnected',
            store.network.isWebSocketConnected
          )
          if (store.network.isWebSocketConnected) {
            await store.session.joinGame()
          }
        } catch (error) {
          console.error('setupGame GameScreen.useEffect error', error)
          // if (error instanceof Error) {
          const message = error?.message ?? 'Unknown Error'
          Alert.alert('Oops', message.substr(0, message.indexOf(':')).slice(0, 200), [
            {
              text: 'Back to Home',
              onPress: () => navigation.navigate(DrawerRoutes.lobby),
            },
          ])
          // }
        }
      }

      const setup = async () => {
        try {
          const shareUserId = store.session.share?.userId ?? 'share'
          const myUserId = store.session.user?._id ?? 'me'
          const hasChannel = !!store.session.channel?.channelName
          const isHost = !hasChannel && myUserId === shareUserId
          // console.log('setupGame GameScreen.useEffect hasChannel', hasChannel)
          // console.log('setupGame GameScreen.useEffect isHost', isHost)
          // // if the host is creating the game and the game has not begun (host didn't re-join or close/reopen the app)
          // if (isHost && store.session.game === undefined) {
          //   console.log('setupGame GameScreen.useEffect creating channel and game...', isHost, myUserId, shareUserId)
          //   store.session.clearMessages()
          //   await Promise.all([store.session.createChannel(), store.session.createGame(myUserId)]).catch((error) =>
          //     console.error('setupGame GameScreen.useEffect isHost', error)
          //   )
          //   console.log('setupGame GameScreen.useEffect channel and game created')
          // }
          console.log('setupGame GameScreen.useEffect joining channel and game...')
          await Promise.all([store.session.createChannelSubscription(true), self.rootStore.session.setupVideo()]).catch(
            (error) => {
              console.error('setupGame GameScreen.useEffect subscriptions', error)
              throw error
            }
          )
          console.log('setupGame GameScreen.useEffect channel and game joined')
          // NOTE: the previous GraphQL subscription lib, need us to check in
          // We are leaving this here in case we experience issues with the newer graphql-ws lib
          // await checkConnection()
        } catch (error) {
          console.error('setupGame GameScreen.useEffect error', error)
          throw error
        }
      }
      await setup()
    },
  }))
  .actions((self) => {
    const onChange = debounce(async (nextAppState: AppStateStatus) => {
      console.debug('session-game app state onChange', nextAppState)
      self.handleAppStateChangeForGame(nextAppState)
    }, 250)

    const onPlayerActionTimerExpired = () => {
      console.log('session-game onPlayerActionTimerExpired')
      // self.noActionPerformed()
    }

    const afterCreate = async () => {
      console.log('session-game afterCreate')
      // clear previous teachers settings.
      self.rootStore.session.setIsCardsVisible(false)
      // await self.wakeUp()
      emitter.addListener(PlayerActionTimerExpired, onPlayerActionTimerExpired)
    }

    const cleanup = () => {
      emitter.removeListener(PlayerActionTimerExpired, onPlayerActionTimerExpired)
      AppState.removeEventListener('change', onChange)
    }

    const afterAttach = () => {
      self.wakeUp()

      // self.clearGame()
      // self.players.clear()
      // self.clearGameWinners()
      // self.modals.clearGameModalQueue()
      // self.setisShowdown(false)
      // restore game state
      if (self.game) {
        console.log('self.isPlayingGame', self.isPlayingGame)
        self.setGame(self.game)
      }

      console.log('session.game afterAttach')
      cleanup()
      AppState.addEventListener('change', onChange)
    }

    const beforeDestroy = () => {
      console.log('session.game beforeDestroy')
      cleanup()
    }

    return { afterCreate, afterAttach, beforeDestroy }
  })

/**
 * The SessionGamesStore instance.
 */
export type SessionGamesStore = Instance<typeof SessionGameModel>

/**
 * The data of a SessionGamesStore.
 */
export type SessionGamesStoreSnapshot = SnapshotOut<typeof SessionGameModel>
